import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators
} from "@angular/forms";
import * as moment from 'moment';
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { NgxSpinnerService } from "ngx-spinner";
import { AppConfig } from "src/app/app.config";
import { DatastoreService } from "src/app/services/datastore.service";
import { DobValidatorService } from "src/app/services/dob-validator.service";
import { ApiService } from "src/app/services/jrni/api.service";
import { AvailabilityService } from "src/app/services/jrni/availability.service";
import { BasketService } from "src/app/services/jrni/basket.service";
import { DepartmentService } from "src/app/services/jrni/department.service";
import { MemberService } from "src/app/services/jrni/member.service";
import { ServicesService } from "src/app/services/jrni/services.service";
import { AlertService } from "src/app/_alert";

@Component({
  selector: "app-booking-details",
  templateUrl: "./booking-details.component.html",
  styleUrls: ["./booking-details.component.scss"],
})
export class BookingDetailsComponent implements OnInit {
  selectedDepartment;
  selectedService;
  pageTitle: string;
  dataPolicy: string;
  dataPolicyCy: string;
  bookingDetailsPageText: string;
  bookingQuestions = [];
  submitted: boolean;
  selectedLocation;
  requiresFullRegistration = false;
  organisationQuestions = [];
  today = new Date();
  selectedFile: string;
  subtmitButtonDisabled: boolean;
  bookingQuestionsConfig = AppConfig.settings.bookingQuestions;
  addresses = [];
  addressFoundBefore = false;
  member;
  slot;
  banned = false;
  customerQuestions;
  // unavailableTimes = [];
  currentYear = new Date().getFullYear();
  // https://regexr.com/3pp3r <- Postcode validator
  postcodeValidator = '^(([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z]))))\s?[0-9][A-Za-z]{2}))$'
  dateId;

  // Initialise the customer form
  customerDetailsForm: FormGroup = new FormGroup({
    first_name: new FormControl("", Validators.required),
    last_name: new FormControl("", Validators.required),
    email_address: new FormControl("", [Validators.required, Validators.pattern(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/i)]),
    mobile_number: new FormControl("", [Validators.minLength(5), Validators.maxLength(11), Validators.pattern(/[0-9]/)]),
    postcode: new FormControl(""),
    selectedAddress: new FormControl(""),
    data_policy: new FormControl(false, Validators.requiredTrue),
    dob_day: new FormControl(false, [Validators.required, Validators.min(1), Validators.max(31)]),
    dob_month: new FormControl(false, [Validators.required, Validators.min(1), Validators.max(12)]),
    dob_year: new FormControl(false, Validators.required),
  });

  constructor(
    private router: Router,
    private servicesService: ServicesService,
    private departmentService: DepartmentService,
    private formBuilder: FormBuilder,
    private basketservice: BasketService,
    private alertService: AlertService,
    private translateService: TranslateService,
    private memberService: MemberService,
    private datastoreService: DatastoreService,
    private spinner: NgxSpinnerService,
    private apiService: ApiService,
    private availabilityService: AvailabilityService,
    public translate: TranslateService,
  ) {
    this.customerDetailsForm.valueChanges.subscribe(x => {
      // new dob calculation
      const dayControl = this.customerDetailsForm.controls.dob_day;
      const day = this.pad(dayControl.value, 2);

      const monthControl = this.customerDetailsForm.controls.dob_month;
      const month = this.pad(monthControl.value, 2);

      const yearControl = this.customerDetailsForm.controls.dob_year;
      const year = this.pad(yearControl.value, 4);

      const dob = year + '-' + month + '-' + day;
      // id of date question is one of these, but we don't know which
      if (this.customerDetailsForm.get("1") !== null) this.dateId = "1";
      if (this.customerDetailsForm.get("279") !== null) this.dateId = "279";
      if (this.customerDetailsForm.get("34") !== null) this.dateId = "34";
      if (!dayControl.value || !monthControl.value || !yearControl.value) {
        this.customerDetailsForm.get(this.dateId).setErrors({
          'pattern': this.translateService.instant('COMMON.DATE_OF_BIRTH_VALIDATION'),
          'code': 'DATE_OF_BIRTH_VALIDATION'
        })
        this.subtmitButtonDisabled = true;
      }
      else {
        const m = moment(dob, 'YYYY-MM-DD');
        if (!m.isValid()) {
          this.customerDetailsForm.get(this.dateId).setErrors({
            'pattern': this.translateService.instant('COMMON.DATE_OF_BIRTH_VALIDATION'),
            'code': 'DATE_OF_BIRTH_VALIDATION'
          });
          this.subtmitButtonDisabled = true;
          return;
        } else {
          this.subtmitButtonDisabled = false;
        }
      }

      if (dayControl.status !== "VALID" || monthControl.status !== "VALID") {
        this.customerDetailsForm.get(this.dateId).setErrors({
          'pattern': this.translateService.instant('COMMON.DATE_OF_BIRTH_VALIDATION'),
          'code': 'DATE_OF_BIRTH_VALIDATION'
        })
        this.subtmitButtonDisabled = true;
      }
      else {
        this.subtmitButtonDisabled = false;

        const age = this.getAge(dob);

        if (age <= 7) {
          this.customerDetailsForm.get(this.dateId).setErrors({
            'pattern': this.translateService.instant('COMMON.AGE_VALIDATION'),
            'code': 'AGE_VALIDATION'
          })
          this.subtmitButtonDisabled = true;
        } else {
          this.subtmitButtonDisabled = false;
          this.customerDetailsForm.get(this.dateId).updateValueAndValidity({
            onlySelf: true
          });
        }
      }
    })
  }

  pad(num: number, size: number): string {
    let s = num + "";
    while (s.length < size) s = "0" + s;
    return s;
  }


  // Initialise the form
  bookingQuestionsForm: FormGroup = this.formBuilder.group({});

  // Getter for easy access to form fields
  get form() {
    return this.customerDetailsForm.controls;
  }
  get bookingForm() {
    return this.bookingQuestionsForm.controls;
  }

  routerGoBack() {
    this.router.navigate(["/calendar"]);
  }

  restartJourney() {
    this.router.navigate(["/departments"]);
  }

  bannedRegistrationCheck(registration) {
    // Banned vehicle registrations from API

    if (this.selectedDepartment.extra.banned_vehicle_registration_numbers) {
      // If there are any banned vehicle registration numbers, run the check
      var str = this.selectedDepartment.extra.banned_vehicle_registration_numbers.replace(/\s/g, '').toLowerCase();
      var banned = new Array();
      banned = str.split(",");

      // Value to return
      var canContinue: Boolean = true;
      // Check for match between banned registrations and users registration
      banned.forEach(item => {
        if (item == registration.replace(/\s/g, '').toLowerCase()) {
          canContinue = false;
        }
      });
      return canContinue;
    } else {
      // If there are no banned vehicle registration numbers, always return true
      return true;
    }

  }

  // Check DOB fields
  checkValue(event, min, max, isYearValue) {
    if (event.target.value < min || event.target.value > max) {
      if (event.target.value != 0) {
        event.target.value = '';
      }

      if (isYearValue) {
        event.target.value = '';
      }
    }
  }

  ngOnInit() {
    this.scrollToTop();
    this.availabilityService.getSelectedSlot().then(slot => {
      if (slot) {
        this.slot = slot
      }
    })
    this.departmentService.get().then((selectedDepartment) => {
      if (selectedDepartment === undefined) {
        this.restartJourney();
      } else {
        this.spinner.show();
        this.selectedDepartment = selectedDepartment;

        let registrationOption = '';
        let organisationQuestionsID;

        // Set the title on the login page to the title from the business question
        this.selectedDepartment.extra?.hasOwnProperty(
          "booking_details_page_title"
        )
          ? (this.pageTitle = this.selectedDepartment.extra.booking_details_page_title)
          : (this.pageTitle = "Booking details title not set");

        // Get the configurable data
        if (this.selectedDepartment.hasOwnProperty("extra")) {
          // Set the extra text on the page to the text taken from the business question

          this.selectedDepartment.extra.hasOwnProperty("privacy_statement_text")
            ? (this.dataPolicy = this.selectedDepartment.extra.privacy_statement_text)
            : (this.dataPolicy = "data policy not set");

          this.selectedDepartment.extra.hasOwnProperty("privacy_statement_text_welsh")
            ? (this.dataPolicyCy = this.selectedDepartment.extra.privacy_statement_text_welsh)
            : (this.dataPolicyCy = "Welsh data policy not set");


          this.selectedDepartment.extra.hasOwnProperty(
            "booking_details_page_text"
          )
            ? (this.bookingDetailsPageText = this.selectedDepartment.extra.booking_details_page_text)
            : null;

          // Set the extra text on the page to the text taken from the business question
          this.selectedDepartment.extra.hasOwnProperty(
            "booking_details_page_text"
          )
            ? (this.bookingDetailsPageText = this.selectedDepartment.extra.booking_details_page_text)
            : null;
          // Get the registration option
          this.selectedDepartment.extra.hasOwnProperty("registration_option")
            ? (registrationOption = this.selectedDepartment.extra
              .registration_option)
            : null;
          // Get the organistion customer questions
          this.selectedDepartment.extra.hasOwnProperty(
            "organisations_questions"
          )
            ? (organisationQuestionsID = this.selectedDepartment.extra
              .organisations_questions)
            : null;

          // If the register option is set to complete a full registration then capture the following details
          if (registrationOption.toLowerCase() === "register complete details") {
            this.requiresFullRegistration = true;
          }
        }

        // Use this.departmentService.getLocation() if the services are set on the children departments
        // Use this.departmentService.get() if the services are set on the parent department
        this.departmentService.getLocation().then(async (location) => {
          this.selectedLocation = location;

          // Delete any exisitng baskets to avoid conflicts
          if (this.basketservice.currentBasket) {
            await this.basketservice.delete(
              this.selectedLocation,
              this.basketservice.currentBasket
            );
          }
          // Create the basket
          this.basketservice.createBasket(location).then(
            (basket) => {
              this.basketservice.currentBasket = basket;

              this.memberService.getMember().then((member) => {
                this.member = member;

                // Get the customer questions
                this.departmentService.getCustomerQuestions().then((questions) => {

                  this.customerQuestions = questions;
                  this.datastoreService.customerQuestions = questions;

                  for (let index = 0; index < this.customerQuestions.length; index++) {
                    const question = this.customerQuestions[index];
                    question.visible = false;
                    question.reference = question.id;
                    if (!question.admin_only) {
                      if (!question.settings.hasOwnProperty('conditional_question')) {
                        question.visible = true;
                      }

                      const questionControl = question.id;
                      if (question.detail_type == 'check' || question.detail_type == 'check-price') {
                        if (question.default == 1) {
                          if (question.required) {
                            this.customerDetailsForm.addControl(questionControl, new FormControl(true, Validators.required));
                          } else {
                            this.customerDetailsForm.addControl(questionControl, new FormControl(true));
                          }
                        } else {
                          if (question.required) {
                            this.customerDetailsForm.addControl(questionControl, new FormControl(false, Validators.required));
                          } else {
                            this.customerDetailsForm.addControl(questionControl, new FormControl(false));
                          }
                        }
                      } else {
                        // Need to add the control for display purposes but we don't need to validate it as we've added three new controls.
                        if (question.id !== 1 && question.id !== 279 && question.id !== 34) {
                          if (question.required) {
                            this.customerDetailsForm.addControl(questionControl, new FormControl('', Validators.required));
                          } else {
                            this.customerDetailsForm.addControl(questionControl, new FormControl(''));
                          }
                        }
                        else {
                          this.customerDetailsForm.addControl(questionControl, new FormControl(''));
                        }
                      }
                    }
                  }
                  this.dateId = "";
                  // id of date question is one of these, but we don't know which
                  if (this.customerDetailsForm.get("1") !== undefined) this.dateId = "1";
                  if (this.customerDetailsForm.get("279") !== undefined) this.dateId = "279";
                  if (this.customerDetailsForm.get("34") !== undefined) this.dateId = "34";
                });
              });
              this.spinner.hide();
            },
            (err) => {
              this.alertService.error(
                this.translateService.instant("COMMON.GENERAL_ERR")
              );
              this.spinner.hide();
            }
          );
        });

        // Get the selected service
        this.servicesService.get().then((service) => {
          this.selectedService = service;

          // Get the questions
          this.selectedService.$get("questions").then((res) => {
            this.bookingQuestions = res.questions;
            this.datastoreService.bookingQuestions = this.bookingQuestions;

            const sortedQuestions = res.questions.sort((a, b) => a.id - b.id);

            for (let index = 0; index < res.questions.length; index++) {
              // ignore the previously asked questions
              const question = sortedQuestions[index];
              if (![275, 276, 277, 278].includes(question.id)) {
                question.visible = false;
                question.reference = question.id;
                if (!question.admin_only) {
                  if (!question.settings.hasOwnProperty("conditional_question")) {
                    question.visible = true;
                  }
                  const questionControl = question.id;

                  if (question.detail_type == 'check' || question.detail_type == 'check-price') {
                    if (question.required) {
                      this.bookingQuestionsForm.addControl(questionControl, new FormControl(false, Validators.requiredTrue));
                    } else {
                      this.bookingQuestionsForm.addControl(questionControl, new FormControl(false));
                    }
                  } else {
                    if (question.required) {
                      this.bookingQuestionsForm.addControl(questionControl, new FormControl('', Validators.required));
                    } else {
                      this.bookingQuestionsForm.addControl(questionControl, new FormControl(''));
                    }
                  }
                }
              }
            }
            // Repopulate the booking questions when coming back
            this.datastoreService.bookingForm
              ? this.repopulateQuestions()
              : null;
          });
        });
      }
    });
  }

  repopulateQuestions() {
    this.bookingQuestionsForm = this.datastoreService.bookingForm;
    this.customerDetailsForm = this.datastoreService.customerDetailsForm;
    this.onQuestionChange();
  }

  onQuestionChange() {
    const bookingQuestions = this.bookingQuestions;
    // Conditional questions logic
    for (let index = 0; index < bookingQuestions.length; index++) {
      const q = bookingQuestions[index];
      // If the question has settings and relies on a conditional question
      if (q.settings && q.settings.hasOwnProperty("conditional_question")) {
        // Get the conditional question based on the id set in q
        const conditionalQ = bookingQuestions.filter(
          (question) => question.id == q.settings.conditional_question
        );
        // Get the question control
        const questionControl = this.bookingForm[q.settings.conditional_question];
        // If the question control has a value get the option from the contional question. This will allow
        // us to check if q is shown when value is selected
        const option = conditionalQ[0].options.filter(
          (option) => option.name == questionControl.value
        );
        // If the conditional question is visible and the value chosen === to qs conditional answer then show q
        if (
          conditionalQ.length > 0 &&
          conditionalQ[0].visible &&
          option.length > 0 &&
          q.settings.conditional_answers.hasOwnProperty(option[0].id)
        ) {
          this.bookingForm[q.id].enable();
          // If the condiditonal question is important, Hide it
          if (q.important) {
            q.visible = false;
          } else {
            q.visible = true;
          }
        } else {
          q.visible = false;
          this.bookingForm[q.id].setValue("");
          this.bookingForm[q.id].disable();
        }
      }
    }
  }

  onFileChange(event, questionId) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.form[questionId].setValue(file, { emitModelToViewChange: false });
      this.bookingQuestionsForm.patchValue({
        fileSource: file,
      });
    }
  }


  getAge(dateString) {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }

  async onSubmit() {
    this.spinner.show();
    this.submitted = true;
    this.alertService.clear();

    const dayControl = this.customerDetailsForm.controls.dob_day;
    const day = this.pad(dayControl.value, 2);

    const monthControl = this.customerDetailsForm.controls.dob_month;
    const month = this.pad(monthControl.value, 2);

    const yearControl = this.customerDetailsForm.controls.dob_year;
    const year = this.pad(yearControl.value, 4);
    if (!dayControl.value || !monthControl.value || !yearControl.value) {
      this.customerDetailsForm.get(this.dateId).setErrors({
        'pattern': this.translateService.instant('COMMON.DATE_OF_BIRTH_VALIDATION'),
        'code': 'DATE_OF_BIRTH_VALIDATION'
      });
    }

    else {
      if (dayControl.status !== "VALID" || monthControl.status !== "VALID") {
        this.customerDetailsForm.get(this.dateId).setErrors({
          'pattern': this.translateService.instant('COMMON.DATE_OF_BIRTH_VALIDATION'),
          'code': 'DATE_OF_BIRTH_VALIDATION'
        });
      }
      else {
        const dob = year + '-' + month + '-' + day;

        let age = this.getAge(dob);
        // id of date question is one of these, but we don't know which
        if (this.customerDetailsForm.controls["1"] !== undefined) this.dateId = "1";
        if (this.customerDetailsForm.controls["279"] !== undefined) this.dateId = "279";
        if (this.customerDetailsForm.controls["34"] !== undefined) this.dateId = "34";

        if (age < 8) {

          this.customerDetailsForm.controls[this.dateId].setErrors({
            'required': this.translateService.instant('COMMON.ADDRESS_NOT_FOUND')
          })

        }
        else {
          this.customerDetailsForm.controls[this.dateId].updateValueAndValidity({
            onlySelf: true
          });
          // now we are validated, we need to set the value into the form control
          this.customerDetailsForm.controls[this.dateId].setValue(dob);
        }
      }
    }

    // Check if form is valid
    if (this.customerDetailsForm.invalid || this.bookingQuestionsForm.invalid) {
      this.scrollToTop();
      this.spinner.hide();
      this.alertService.warn(this.translateService.instant('FORM.VALIDATION_ERR_MSG'));
      return;
    }


    var currentDate = new Date(this.slot.start);

    // for some reason this function sometimes doesn't get run, adding the console logs fixed this (not sure why)

    let phone = this.customerDetailsForm.controls['mobile_number'].value;

    // Remove the 0 char at the as JRNI remove 0 from all phone numbers
    while (phone.charAt(0) === '0') {
      phone = phone.substring(1);
    }

    // await this.apiService.checkForDoubleBooking(
    //   this.selectedDepartment,
    //   currentDate,
    //   this.customerDetailsForm.value.email_address ? this.customerDetailsForm.value.email_address : null,
    //   this.customerDetailsForm.value.mobile_number ? phone : null,
    // ).then((res: []) => {
      // res = array of unavailable times
      // this.unavailableTimes = res

      this.completeBooking()
    // })
  }

  async completeBooking() {
    this.datastoreService.bookingForm = this.bookingQuestionsForm;
    this.datastoreService.customerDetailsForm = this.customerDetailsForm;
    this.spinner.hide();

    this.router.navigate(["/summary"]);
  }

  // Scroll to the top of the page
  scrollToTop() {
    window.scroll(0, 0);
  }

  // Number input text validation, keycode 8 is backspace
  keyPressNumberField(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
}
