import { Pipe, PipeTransform } from '@angular/core';
import { Globals } from '../globals';

// Split pipe used for text translations provided by booking bug in the format:
// "English" | "Welsh"

@Pipe({
  name: 'translateSplit',
  pure: false
})
export class TranslateSplitPipe implements PipeTransform {
  globals: Globals;
  constructor(
    globals: Globals,
  ) {
    this.globals = globals;
  }

  transform(value: string, ...args: unknown[]): unknown {
    // Globals
    // English = 0
    // Welsh = 1

    // No pipe, Give the string back unsplit
    if (value) {
      if (value.includes('|')) {
        return value.split('|')[this.globals.blLanguage];
      } else {
        return value;
      }
    }
  }

}
