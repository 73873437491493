// locale configuration
// locale : Welsh [cy]

import { Injectable  } from '@angular/core';
import { MatDatepickerIntl } from '@angular/material/datepicker';

import * as moment from 'moment';
import './moment.cy';


/** Datepicker data that requires internationalization. */
@Injectable({ providedIn: 'root' })
export class CalendarCy extends MatDatepickerIntl {
  constructor() {
    super();
    moment.locale('cy');
  }

  /** A label for the calendar popup (used by screen readers). */
  calendarLabel = 'Calendr';

  /** A label for the button used to open the calendar popup (used by screen readers). */
  openCalendarLabel = 'Calendr agored';

  /** A label for the previous month button (used by screen readers). */
  prevMonthLabel = 'Y mis blaenorol';

  /** A label for the next month button (used by screen readers). */
  nextMonthLabel = 'Mis nesaf';

  /** A label for the previous year button (used by screen readers). */
  prevYearLabel = 'Y flwyddyn flaenorol';

  /** A label for the next year button (used by screen readers). */
  nextYearLabel = 'Blwyddyn nesaf';

  /** A label for the 'AM' button (used by screen readers). */
  setToAMLabel = 'YN';

  /** A label for the 'PM' button (used by screen readers). */
  setToPMLabel = 'Post yh';

  /** A label for the 'switch to minute view' button (used by screen readers). */
  switchToMinuteViewLabel = 'newid i olwg munud';

  /** A label for the 'switch to hour view' button (used by screen readers). */
  switchToHourViewLabel = 'newid i olygfa awr';

  /** A label for the 'switch to month view' button (used by screen readers). */
  switchToMonthViewLabel = 'newid i olwg mis';

  /** A label for the 'switch to year view' button (used by screen readers). */
  switchToYearViewLabel = 'newid i olwg blwyddyn';

  /** A label for the 'switch to years view' button (used by screen readers). */
  switchToYearsViewLabel = 'newid i olwg blynyddoedd';

  /** Text for the 'submit' button. */
  buttonSubmitText = 'Iawn';

  /** A label for the 'submit' button (used by screen readers). */
  buttonSubmitLabel = 'cadarnhau\'r dewis';

  /** Text for the 'cancel' button. */
  buttonCancelText = 'canslo\'r dewis';

  /** A label for the 'cancel' button (used by screen readers). */
  buttonCancelLabel = '';
}