import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-session',
  templateUrl: './session.component.html',
  styleUrls: ['./session.component.scss']
})
export class SessionComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  restart(): void {
    this.router.navigate(['/departments']);
  }  
}
