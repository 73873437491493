import { Component } from '@angular/core';
import { context } from 'bookingbug-core-js';
import { AppConfig } from './app.config';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { DatastoreService } from './services/datastore.service';
import { AlertService } from './_alert';
import { BnNgIdleService } from 'bn-ng-idle'; // import it to your component

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  loadIssue: boolean;

  constructor(
    private translate: TranslateService,
    private router: Router,
    private DataStore: DatastoreService,
    private alertService: AlertService,
    private bnIdle: BnNgIdleService) {
    translate.setDefaultLang('en');

    this.DataStore.notify.subscribe((result) => {
      this.loadIssue = result;
      this.scrollToTop();
      this.alertService.clear();
      this.alertService.error(this.translate.instant('COMMON.LOAD_ISSUE'));
    })
  }

  ngOnInit() {
    // BB Context storage
    context.apiUrl = AppConfig.settings.apiServer.api_url;
    context.apiVersion = AppConfig.settings.apiServer.version;

    this.bnIdle.startWatching(900).subscribe((res) => {
      this.router.navigate(['/sessionExpired']);
    });
  }

  scrollToTop() {
    const scrollToTop = window.setInterval(() => {
      const pos = window.pageYOffset;
      if (pos > 0) {
        window.scrollTo(0, pos - 20);
      } else {
        window.clearInterval(scrollToTop);
      }
    }, 10);
  }
}

