import { Component, OnInit } from '@angular/core';
import { MemberService } from '../services/jrni/member.service';
import { Router } from '@angular/router';
import { LoginService } from '../services/jrni/login.service';

@Component({
  selector: 'app-member-bar',
  templateUrl: './member-bar.component.html',
  styleUrls: ['./member-bar.component.scss']
})
export class MemberBarComponent implements OnInit {
  member;
  name;
  currentLink: String;

  constructor(
    private memberService: MemberService,
    private router: Router,
    private loginService: LoginService
  ) { }

  ngOnInit() {
    this.memberService.getMember().then(member => {
      this.member = member;
      if (member) {
        member.first_name ? this.name = member.first_name : this.name = member.last_name;
      }

      this.currentLink = this.router.url;
    });
  }

  logout() {
    this.loginService.logout();
  }
}
