import { Injectable } from '@angular/core';
import { BBService, BBModel } from 'bookingbug-core-js';
import { AppConfig } from 'src/app/app.config';
import { HttpHeaders, HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ClientService {
  private core = AppConfig.settings.core;
  private apiServer = AppConfig.settings.apiServer;
  constructor(
    private http: HttpClient
  ) { }

  async create(company, client) {
    try {
      const res = await company.$post('client', {}, { ...client });
      return res;
    } catch (error) {
      return error;
    }
  }
}
