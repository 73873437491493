import { NativeDateAdapter } from '@angular/material/core';
import { Injectable } from "@angular/core";
import {DatePipe} from '@angular/common';

export interface DateDisplay {
  year: string;
  month: string;
  day: string;
}

export const CUSTOM_DATE_FORMATS = {
    parse: {
        dateInput: {month: 'short', year: 'numeric', day: 'numeric'}
    },
    display: {
        //dateInput: { month: 'short', year: 'numeric', day: 'numeric'},
        dateInput: 'customInput',
        monthYearLabel: {year: 'numeric', month: 'short'},
        dateA11yLabel: {year: 'numeric', month: 'long', day: 'numeric'},
        monthYearA11yLabel: {year: 'numeric', month: 'long'},
    }
};


/** Adapts the native JS Date for use with cdk-based components that work with dates. */
@Injectable()
export class CustomDateAdapter extends NativeDateAdapter {

    langs = {'en': 'en', 'cy': 'cy'};

    // parse the date from input component as it only expect dates in 
    // mm-dd-yyyy format
    parse(value: any): Date | null {
        if ((typeof value === 'string') && (value.indexOf('/') > -1)) {
            const str = value.split('/');

            const year = Number(str[2]);
            const month = Number(str[1]) - 1;
            const date = Number(str[0]);

            return new Date(year, month, date);
        }
        const timestamp = typeof value === 'number' ? value : Date.parse(value);
        return isNaN(timestamp) ? null : new Date(timestamp);
    }

    getFirstDayOfWeek(): number {
        return 1;
    }

    format(date: Date, display: string | DateDisplay): string {
        if (display === 'customInput') {
          return new DatePipe(this.langs[this.locale]).transform(date, 'shortDate');
        } else {
          return  new DatePipe(this.langs[this.locale]).transform(date, 'MMM yyyy');
        }
      }
}