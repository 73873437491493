import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DepartmentService } from 'src/app/services/jrni/department.service';
import { LeafletMapComponent } from 'src/app/leaflet-map/leaflet-map.component';
import { AlertService } from 'src/app/_alert';
import { NgxSpinnerService } from 'ngx-spinner';
import { TranslateService } from '@ngx-translate/core';
import { DatastoreService } from 'src/app/services/datastore.service';
import { AvailabilityService } from 'src/app/services/jrni/availability.service';
import { ServicesService } from 'src/app/services/jrni/services.service';
import { AppConfig } from 'src/app/app.config';

@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.scss']
})
export class LocationComponent implements OnInit {

  // Access the leaflet components properties
  @ViewChild(LeafletMapComponent) leafletMapComponent: LeafletMapComponent;

  selectedDepartment;
  pageTitle;
  locationPageText;
  locations;
  selectedLocation;
  mapsRequired: boolean;
  private core = AppConfig.settings.core;

  constructor(
    private router: Router,
    private departmentService: DepartmentService,
    private alertService: AlertService,
    private spinner: NgxSpinnerService,
    public translateService: TranslateService,
    private datastoreService: DatastoreService,
    private availabilityService: AvailabilityService,
    private servicesService: ServicesService
  ) { }

  routerGoBack() {
    this.locations = [];
    this.datastoreService.locations = [];
    this.router.navigate(['/services']);
  }

  restartJourney() {
    this.router.navigate(['/departments']);
  }

  ngOnInit() {
    this.scrollToTop();
    this.spinner.show();
    // if we switched to the alt company, switch back
    if (this.datastoreService.selectedDepartment !== undefined) {
      this.servicesService.set(this.datastoreService.selectedService);
      this.departmentService.set(this.datastoreService.selectedDepartment);
      this.datastoreService.selectedDepartment = undefined;
    }
    // Use this.departmentService.getLocation() if the services are set on the children departments
    // Use this.departmentService.get() if the services are set on the parent department
    this.departmentService.get().then(async (selectedDepartment) => {
      this.locations = null;
      this.datastoreService.locations = null;
      if (selectedDepartment === undefined) {
        this.restartJourney();
      } else {
        this.selectedDepartment = selectedDepartment;

        // Get the configurable data
        if (this.selectedDepartment.hasOwnProperty('extra')) {
          // Set the extra text on the page to the text taken from the business question
          this.selectedDepartment.extra.hasOwnProperty('location_page_text') ? this.locationPageText = this.selectedDepartment.extra.location_page_text : null;
          // Check if maps are required
          this.selectedDepartment.extra.hasOwnProperty('maps') ? this.selectedDepartment.extra.maps.toLowerCase() === 'disabled' ? this.mapsRequired = false : this.mapsRequired = true : null;
        }

        // Get the children departments (locations) from the selected department
        await this.departmentService.getSelectedDepartmentChildren(this.selectedDepartment.id, this.servicesService.selectedService.id).then(async (locations) => {
          // get those other 2 locations from the alt company and slot em in
          await this.departmentService.getSelectedDepartmentChildren(this.core.alt_company_id, this.core.alt_service_id).then(async (locationsDnR) => {
            for (let i in locationsDnR) {
              const subServices = await this.servicesService.getServices(locationsDnR[i])
              const matchingService = subServices.filter(x => x.name === this.datastoreService.selectedService.name)
              if (matchingService.length > 0) { // if locations do have the service we are looking at it, add them
                locations.push(locationsDnR[i])
              }
            }
            locations = locations.sort((a, b) => a.name.localeCompare(b.name))
            await this.departmentService.setLocationList(locations).then((filteredLocations) => {
              // Filter locations on the service & then display them here
              this.locations = filteredLocations;
              this.datastoreService.locations = filteredLocations;
              this.skipLocationPage();
              this.spinner.hide();
            });

          });

        }, err => {
          console.error(err);
          this.alertService.error(this.translateService.instant('COMMON.GENERAL_ERR'))
          this.spinner.hide();
        });
      }
    }, err => {
      this.alertService.error(this.translateService.instant('COMMON.GENERAL_ERR'))
      this.spinner.hide();
    });
  }

  // If there is only one location then skip over the location page
  skipLocationPage() {
    if (this.locations.length === 1) {
      this.selectedLocation = this.locations[0];
      this.continue();
    }
  }

  updateMapView(lat, long) {
    if (this.mapsRequired) {
      this.leafletMapComponent.renderMapLocation(lat, long);
    }
  }

  selectLocation(location) {
    this.selectedLocation = location;

    // set the wheelchair accessible resource
    for (const resource of this.departmentService.accessibleResources) {
      if (resource.company_id == location.id) {
        this.availabilityService.selectedResource = resource;
        break;
      }
    }
  

    this.locations.forEach(location => {
      if (location != this.selectedLocation)
        this.datastoreService.unselectedLocations.push(location);
    });

    this.continue();
  }

  continue() {
    // Use this.departmentService.getLocation() if the services are set on the children departments
    // Use this.departmentService.get() if the services are set on the parent department
    this.departmentService.get().then(location => {
      if (this.selectedLocation) {
        if (location && location !== this.selectedLocation) {
          this.datastoreService.selectedDate = null;
          this.availabilityService.setSelectedSlot(null);
        }
        this.departmentService.setLocation(this.selectedLocation);
        // If we picked an alt company as our cmpany, change up the service and parent company
        if (this.selectedLocation.membership_id.toString() === this.core.alt_company_id) {
          this.departmentService.getAltParentDepartment().then(altDepartment => {
            this.servicesService
              .getServices(altDepartment)
              .then((altServices) => {
                // const altService = altServices.filter(x => x.id.toString() === this.core.alt_service_id)[0]
                const altService = altServices.filter(x => x.name === this.datastoreService.selectedService.name)[0]

                if (altService) {
                  altDepartment.extra = location.extra;
                  this.datastoreService.selectedDepartment = this.selectedDepartment;

                  this.servicesService.set(altService);
                  this.departmentService.set(altDepartment)
                  console.log("altDepartment")
                  this.router.navigate(['/calendar']);
                } else {
                  this.alertService.warn("Error loading alt department data");

                  this.scrollToTop();
                }
              });
          });

        } else {
          this.router.navigate(['/calendar']);
        }
      } else {
        this.alertService.warn("Please select a location before proceeding");

        this.scrollToTop();
      }
    });
  }

  // scroll to the top of the page
  scrollToTop() {
    window.scroll(0, 0);
  }
}
