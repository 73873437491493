import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { AvailabilityService } from 'src/app/services/jrni/availability.service';
import { BasketService } from 'src/app/services/jrni/basket.service';
import { DepartmentService } from 'src/app/services/jrni/department.service';
import { ServicesService } from 'src/app/services/jrni/services.service';
import { AlertService } from 'src/app/_alert';
import { DatastoreService } from 'src/app/services/datastore.service';
import { MemberService } from 'src/app/services/jrni/member.service';
import { CalendarService } from 'src/app/services/calendar.service';
import { AppConfig } from "src/app/app.config";

import { take } from 'rxjs/operators';
import * as moment from 'moment';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements OnInit {
  selectedDepartment;
  calendarPageText;
  minDate;
  maxDate;
  selectedDate: Date;
  currentDate: Date;
  selectedService;
  selectedLocation;
  slots: [];
  selectedSlot;
  availableDays: any;
  component: String;
  bookingsLimit: String;
  unselectedLocations = [];
  dateToCheck = '';
  locationName;
  loading = false;
  private core = AppConfig.settings.core;
  altcompany = false;


  constructor(
    private router: Router,
    private departmentService: DepartmentService,
    private servicesService: ServicesService,
    private availabilityService: AvailabilityService,
    private alertService: AlertService,
    private basketService: BasketService,
    public translateService: TranslateService,
    private spinner: NgxSpinnerService,
    private datastoreService: DatastoreService,
    private memberService: MemberService,
    private calendarService: CalendarService
  ) {
  }

  routerGoBack() {
    this.calendarService.currentlySelectedSlot = null;
    this.calendarService.savedLocations = null;
    this.calendarService.unselectedLocations = null;
    this.datastoreService.unselectedLocations = [];
    this.router.navigate(['/locations']);
  }

  restartJourney() {
    this.router.navigate(['/departments']);
  }

  slotUpdated(data) {
    this.selectedSlot = data;
    this.getUnselectedLocationSlots(data?.start)
  }

  ngOnInit() {
    this.spinner.show();
    this.loading = true;
    this.scrollToTop();
    this.component = "calendar";
    // Use this.departmentService.getLocation() if the services are set on the children departments
    // Use this.departmentService.get() if the services are set on the parent department
    this.departmentService.get().then(selectedDepartment => {
      if (selectedDepartment === undefined) {
        this.restartJourney();
      } else {
        this.selectedDepartment = selectedDepartment;

        // Set the current date to today for the calendar to default to today
        this.currentDate = new Date();
        this.selectedDate = this.currentDate;

        // Get the configurable data
        if (this.selectedDepartment.hasOwnProperty('extra')) {
          // Set the extra text on the page to the text taken from the business question
          this.selectedDepartment.extra.hasOwnProperty('calendar_page_text') ? this.calendarPageText = this.selectedDepartment.extra.calendar_page_text : null;
        }

        // Retrieve the selected service
        this.servicesService.get().then(service => {
          this.selectedService = service;

          const minAdvTime = new Date(this.selectedService.min_advance_datetime);
          this.minDate = moment(minAdvTime).add(4, 'm').toDate();
          this.maxDate = new Date(this.selectedService.max_advance_datetime);

          
          this.departmentService.getLocation().then(async (selectedLocation) => {
            this.selectedLocation = selectedLocation;

            // Clear the basket
            this.basketService.deleteAllBaskets(this.selectedDepartment).catch((err) => {
              this.alertService.error(this.translateService.instant('COMMON.GENERAL_ERR'));
            });

          });
        });
      }
    }, err => {
      this.alertService.error(this.translateService.instant('COMMON.GENERAL_ERR'))
      this.spinner.hide();
    });
  }

  getUnselectedLocationSlots(selectedSlot) {
    this.loading = true;
    this.unselectedLocations = [];
    this.calendarService.currentlySelectedSlot = selectedSlot;
    this.servicesService.get().then(async (service) => {
      // alt company does not have other available times
      if(service.company_id.toString() === this.core.alt_company_id){ 
        this.loading = false;
        this.altcompany = true;
        return
      } else {
        this.altcompany = false;
      }
      const isoTime = selectedSlot.split('T')[0];
      await this.calendarService.checkAvailability(service, isoTime, true).then((unselectedLocations) => {
        this.loading = false;
        // This function returns all the locations
        for (let l of unselectedLocations) {
          if (l.name !== this.selectedLocation.name) {
            if (l.hasEarlierAvailability) {
              if (!this.unselectedLocations.includes(l)) {
                this.unselectedLocations.push(l);
              }
            }
          }
        }
      });
    });
  }

  switchLocation(location) {
    this.spinner.show()
    this.alertService.clear();

    this.scrollToTop();
    this.component = "calendar";

    // update the list of available locations
    this.datastoreService.unselectedLocations = [];
    for (let l of this.datastoreService.locations) {
      if (l != location)
        this.datastoreService.unselectedLocations.push(l)
    }

    this.departmentService.setLocation(location).then(() => {
      this.departmentService.getLocation().then(selectedLocation => {
        this.calendarService.switchLocation(location);
        this.selectedLocation = selectedLocation;
        this.getUnselectedLocationSlots(this.selectedSlot.start);

        this.availabilityService.setSelectedSlot(this.selectedSlot);

        // set resource
        if(this.departmentService.accessibleResources.length > 0) {
          for(const r of this.departmentService.accessibleResources) {
            if(r.company_id == this.selectedLocation.id) {
              this.availabilityService.selectedResource = r;
              break;
            }
          }
        }

        this.locationName = this.translateService.store.currentLang == "cy" ? location?.extra?.location_name_welsh
          : location.name;

        this.alertService.success(this.translateService.instant('COMMON.LOCATION_CHANGE') + this.locationName);
        this.spinner.hide();
      }, (e) => {
        this.alertService.error(this.translateService.instant('COMMON.GENERAL_ERR'));
        this.spinner.hide();
      })
    }, (e) => {
      this.alertService.error(this.translateService.instant('COMMON.GENERAL_ERR'));
      this.spinner.hide();
    })
  }

  continue() {
    this.alertService.clear();
    this.availabilityService.getSelectedSlot().then(slot => {
      if (slot) {
        // Store the selected date
        this.datastoreService.selectedDate = this.selectedDate;
        // Store the selected slot
        this.datastoreService.selectedSlot = slot;
        this.router.navigate(['/booking-details']);
      } else {
        this.alertService.warn(this.translateService.instant('CALENDAR.SLOT_WARNING'));
        this.scrollToTop();
      }
    });
  }

  // scroll to the top of the page
  scrollToTop() {
    window.scroll(0, 0);
  }
}
