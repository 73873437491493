import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DepartmentService } from 'src/app/services/jrni/department.service';
import { AlertService } from 'src/app/_alert';
import { NgxSpinnerService } from 'ngx-spinner';
import { BasketService } from 'src/app/services/jrni/basket.service';
import { TranslateService } from '@ngx-translate/core';
import { DatastoreService } from "src/app/services/datastore.service";
import { ServicesService } from 'src/app/services/jrni/services.service';
import { AvailabilityService } from 'src/app/services/jrni/availability.service';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})
export class ConfirmationComponent implements OnInit {
  selectedDepartment;
  pageTitle: string;
  confirmationPageText: string;
  purchase;
  bookings;
  selectedService;
  selectedLocation;
  address;
  selectedDate;
  selectedSlot;
  client;
  endSlot
  total;
  extraDetailsPresent
  bookingSummaryData = [];
  bookingsPlaceholder;


  constructor(
    private router: Router,
    private departmentService: DepartmentService,
    private alertService: AlertService,
    private spinner: NgxSpinnerService,
    private basketService: BasketService,
    public translateService: TranslateService,
    private servicesService: ServicesService,
    private datastoreService: DatastoreService,
    private availabilityService: AvailabilityService,
  ) { }

  restartJourney() {
    this.router.navigate(['/departments']);
  }

  ngOnInit() {
    this.scrollToTop();
    this.spinner.show();
    // Use this.departmentService.getLocation() if the services are set on the children departments
    // Use this.departmentService.get() if the services are set on the parent department
    this.departmentService.getLocation().then(async (selectedDepartment) => {
      if (selectedDepartment === undefined) {
        this.restartJourney();
      } else {
        this.client = this.datastoreService.customerDetailsForm.value;
        this.selectedDepartment = await selectedDepartment.$getParent();
        // Get the configurable data
        if (this.selectedDepartment.hasOwnProperty('extra')) {
          // Set the title on the login page to the title from the business question
          this.selectedDepartment.extra.hasOwnProperty('confirmation_page_title') ? this.pageTitle = this.selectedDepartment.extra.confirmation_page_title : this.pageTitle = "Confirmation title not set";
          // Set the extra text on the page to the text taken from the business question
          this.selectedDepartment.extra.hasOwnProperty('confirmation_page_text') ? this.confirmationPageText = this.selectedDepartment.extra.confirmation_page_text : null;
        }

        // Get the selected service
        this.servicesService.get().then((service) => {
          this.selectedService = service;
          
          this.datastoreService.bookingQuestions.forEach(question => {
            if (this.datastoreService.bookingForm.value[question['id']]) {
              this.bookingSummaryData.push({
                name: question.name,
                answer: this.datastoreService.bookingForm.value[question.id],
                detail_type: question.detail_type,
                price: question.price
              });
            }
          });
          // Check if the questions contain any other purchase details
          const extraDetails = this.bookingSummaryData.filter(elem => elem.detail_type !== 'check-price');
          extraDetails.length > 0 ? this.extraDetailsPresent = true : this.extraDetailsPresent = false;
          // Calculate purchase total
          const paidItems = this.bookingSummaryData.filter(item => item.detail_type === 'check-price');
          this.total = paidItems
            .map((items) => items.price)
            .reduce((a, b) => a + b, 0) + this.selectedService?.price;
        });

        // Get the selected slot
        if (this.datastoreService.selectedSlot) {
          this.availabilityService.getSelectedSlot().then((slot) => {
            this.selectedSlot = slot;
            this.availabilityService.getEndSlot().then((slot) => {
              this.endSlot = slot;
            });
          });
        }
        // this.selectedSlot = this.datastoreService.selectedSlot;
        // Get the selected location
        // Use this.departmentService.getLocation() if the services are set on the children departments
        // Use this.departmentService.get() if the services are set on the parent department
        this.departmentService.getLocation().then(location => {
          this.selectedLocation = location;
          location?.$getAddress().then(res => {
            this.address = res
          });
        });
        // Get the purchase
        this.basketService.getPurchase().then(purchase => {
          this.purchase = purchase;
          purchase.$get('bookings').then(bookings => {
            this.bookings = bookings[0];
            this.bookingsPlaceholder = {
              referenceNo: this.bookings.id
            };
          });
        }, (err) => {
          this.alertService.error(this.translateService.instant('COMMON.GENERAL_ERR'))
          this.spinner.hide();
        });
        this.spinner.hide();
      }
    });
  }

  // print confirmation page
  print() {
    window.print();
  }

  // Start at the top of the page
  scrollToTop() {
    window.scroll(0, 0);
  }
}
