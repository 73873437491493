import { Component, OnInit } from '@angular/core';
import { IBreadCrumb } from './breadcrumb.interface';
import { Router, ActivatedRoute, NavigationEnd, Event } from '@angular/router';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { DepartmentService } from '../services/jrni/department.service';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements OnInit {
  public breadcrumbs: IBreadCrumb[]
  currentUrl: string;
  hideBreadcrumbs: boolean;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private departmentService: DepartmentService
  ) {
    this.breadcrumbs = this.buildBreadCrumb(this.activatedRoute.root, '');
  }


  ngOnInit() {
    this.router.events.pipe(
      filter((event: Event) => event instanceof NavigationEnd),
      distinctUntilChanged(),
    ).subscribe(async (event: NavigationEnd) => {
      this.breadcrumbs = this.buildBreadCrumb(this.activatedRoute.root, event.url);

      this.currentUrl = event.url;

      // Set location always visited
      if (this.currentUrl === '/departments' || this.currentUrl === '/account' || this.currentUrl === '/') {
        this.hideBreadcrumbs = true;
      } else {
        this.hideBreadcrumbs = false;
      }
    // Use this.departmentService.getLocation() if the services are set on the children departments
    // Use this.departmentService.get() if the services are set on the parent department
      const selectedDepartment = await this.departmentService.get();
      // If location is before services then swap services and location in array
      // if (selectedDepartment) {
      //   if (selectedDepartment.extra.service_or_location_first.toLowerCase() === "location") {
      //     this.swapItems(this.breadcrumbs, 1, 2);
      //   }
      // }
      // If login is not required then remove from breadcrumb
      if (selectedDepartment) {
        if (selectedDepartment && selectedDepartment.extra.login_required.toLowerCase() === "no") {
          this.breadcrumbs.shift();
        }
      }

      // Gets the index of the url we are currently on
      const existingIndex = this.breadcrumbs.findIndex(e => `/${e.url}` == event.url);

      // Gets the visited URLs (handles if the users goes back)
      const visitedUrls = this.breadcrumbs.slice(0, existingIndex + 1);

      // Checks the breadcrumbs against the visisted URLS and sets breadcrumb to visited
      this.breadcrumbs.forEach(element => {
        visitedUrls.forEach(visitedElement => {
          if (element.url == visitedElement.url) {
            element.visited = true;
          }
        });
      });
    });
  }

  // Builds the array of breadcrumbs using the router
  buildBreadCrumb(activatedRoute: ActivatedRoute, url: string, breadcrumbs: IBreadCrumb[] = []): IBreadCrumb[] {
    let newBreadcrumbs = [];
    for (let index = 0; index < this.router.config.length; index++) {
      const route = this.router.config[index];

      const breadcrumb: IBreadCrumb = {
        label: route.data ? route.data.breadcrumb : null,
        url: route.path,
        visited: false
      };

      // Set location always visited
      if (breadcrumb.url === 'login') {
        breadcrumb.visited = true;
      }

      let responseCode;
      activatedRoute.queryParams.subscribe(params => {
        responseCode = params['responseCode'];
      });

      // Set all breadcrumbs to visited
      if (url && url.includes('confirmation') && responseCode && responseCode == '00') {
        breadcrumb.visited = true;
      }

      // Set all breadcrumbs before confirmation to visited
      if (url && url.includes('confirmation') && responseCode && responseCode == '101' && breadcrumb.url != 'confirmation') {
        breadcrumb.visited = true;
      }

      breadcrumb.label ? newBreadcrumbs.push(breadcrumb) : {};
    }

    return newBreadcrumbs;
  }

  swapItems(input, index_A, index_B) {
    let temp = input[index_A];

    input[index_A] = input[index_B];
    input[index_B] = temp;
  }
}
