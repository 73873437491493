import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { AppConfig } from 'src/app/app.config';
import { context } from 'bookingbug-core-js';

@Injectable({
  providedIn: "root",
})
export class ApiService {
  private core = AppConfig.settings.addressApi;
  private token = 'b08e3b9583f825975eebadd033da710633b44bbfbf04ee67150c0563beb2be70';
  private apiServer = 'https://api.bookinglab.co.uk';

  constructor(private http: HttpClient) { }

  get(postcode) {
    const key = this.core.key
    return this.http.get(
      `https://api.os.uk/search/places/v1/postcode?postcode=${postcode}&key=${key}&dataset=DPA,LPI`
    );
  }

  async getWeekOfBookings(address, company) {
    const headers = { 'Content-Type': 'application/json', 'X-BL-TOKEN': `${this.token}` };
    const body = {
      "host": `${context.apiUrl}`,
      "company_id": `${company.id}`,
      "limit": `${company.extra.booking_limit_days}`,
      "address": `${address}`,
    }

    return await this.http.post(`${this.apiServer}/weeklyLimit`, body, { headers }).toPromise();
  }


  async checkEmailNumber(email, mobile, company) {
    const headers = { 'Content-Type': 'application/json', 'X-BL-TOKEN': `${this.token}` };
    const body = {
      "host": `${context.apiUrl}`,
      "company_id": `${company.id}`,
      "email": email ? email : mobile,
      "searchType": "elastic"
    }

    return await this.http.post(`${this.apiServer}/findElasticClient`, body, { headers }).toPromise();
  }

  async getBookingsForUser(company, email) {
    const headers = { 'Content-Type': 'application/json', 'X-BL-TOKEN': `${this.token}` };
    const body = {
      "host": `${context.apiUrl}`,
      "company_id": `${company.id}`,
      "email": `${email}`,
    }

    return await this.http.post(`${this.apiServer}/findElasticClient`, body, { headers }).toPromise();
  }

  async checkForDoubleBooking(company, slot, email = null, mobile = null) {
    const headers = { 'Content-Type': 'application/json', 'X-BL-TOKEN': `${this.token}` };
    const body = {
      "host": `${context.apiUrl}`,
      "company_id": `${company.id}`,
      "current_date": slot,
    }

    if (email) {
      body["email"] = email;
    } else {
      body["mobile"] = mobile;
    }

    return this.http.post(`${this.apiServer}/unavailableTimes`, body, { headers }).toPromise();
  }

  async checkSlotAvailability(company, slot) {
    const headers = { 'Content-Type': 'application/json', 'X-BL-TOKEN': `${this.token}` };
    const body = {
      "host": `${context.apiUrl}`,
      "company_id": `${company.id}`,
      "slot": slot,
    }

    return this.http.post(`${this.apiServer}/slotAvailability`, body, { headers }).toPromise();
  }
}