import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

// Split pipe used for splitting text into new lines

@Pipe({
    name: 'lineSplit',
    pure: false
  })
  export class LineSplitPipe implements PipeTransform {
    constructor(private sanitizer: DomSanitizer) {
    }
  
    transform(value: string, ...args: unknown[]): unknown {

      // No pipe, Give the string back unsplit
      if (value) {
        if (value.includes(',')) {
          const segments = value.split(',');
          return this.sanitizer.bypassSecurityTrustHtml(segments.join("<br />"));
        } else {
          return value;
        }
      }
    }
  
  }