import { AfterViewInit, Component, Input } from "@angular/core";
import { DatastoreService } from "../services/datastore.service";
declare let L;
declare var GreyscaleMap: any;

@Component({
  selector: "app-leaflet-map",
  templateUrl: "./leaflet-map.component.html",
  styleUrls: ["./leaflet-map.component.scss"],
})
export class LeafletMapComponent implements AfterViewInit {
  marker;
  lat;
  long;
  @Input() confirmationPage: boolean;
  @Input() num: any;


  constructor(private datastoreService: DatastoreService) {}

  ngAfterViewInit() {

    // set map coordinates
    this.lat = this.datastoreService.latitude;
    this.long = this.datastoreService.longitude;

    const map = L.map(`map${this.num}`, { attributionControl: false }).setView(
      [this.lat, this.long],
      15
    );

    L.tileLayer("https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png", {
      attribution: "Bookinglab",
    }).addTo(map);

    this.marker = L.marker([this.lat, this.long]).addTo(map);
  }

  renderMapLocation(lat, long) {
 /*    this.marker.setLatLng([lat, long]).update();

    const map.flyTo([lat, long], 15, {
      animate: true,
      duration: 0.6,
    }); */
  }
}
