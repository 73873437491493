import { Component, OnInit } from "@angular/core";
import { ServicesService } from "../services/jrni/services.service";
import { DepartmentService } from "../services/jrni/department.service";
import { AvailabilityService } from "../services/jrni/availability.service";
import { DatastoreService } from "../services/datastore.service";
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: "app-booking-summary",
  templateUrl: "./booking-summary.component.html",
  styleUrls: ["./booking-summary.component.scss"],
})
export class BookingSummaryComponent implements OnInit {
  selectedService;
  selectedLocation;
  selectedSlot;
  selectedDepartment;
  address;
  endSlot;
  currentLang: string;

  constructor(
    private servicesService: ServicesService,
    private departmentService: DepartmentService,
    private availabilityService: AvailabilityService,
    private datastoreService: DatastoreService,
    public translateService: TranslateService
  ) {}

  ngOnInit() {
    this.currentLang = this.translateService.store.currentLang;

    // Get the selected service
    this.servicesService.get().then((service) => {
      this.selectedService = service;
    });

    // Get the selected location
    // Use this.departmentService.getLocation() if the services are set on the children departments
    // Use this.departmentService.get() if the services are set on the parent department
    this.departmentService.getLocation().then((location) => {
      this.selectedLocation = location;
    });

    // Get the selected address
    // Use this.departmentService.getLocation() if the services are set on the children departments
    // Use this.departmentService.get() if the services are set on the parent department
    this.departmentService.getLocation().then((res) => {
      this.address = res;
    });

    // Get the selected slot and end of slot
    if (this.datastoreService.selectedSlot) {
      this.availabilityService.getSelectedSlot().then((slot) => {
        this.selectedSlot = slot;
      });
      this.availabilityService.getEndSlot().then((slot) => {
        this.endSlot = slot;
        this.datastoreService.selectedSlot = slot;
      });
    }
  }
}
