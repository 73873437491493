import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { AvailabilityService } from 'src/app/services/jrni/availability.service';
import { AlertService } from 'src/app/_alert';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import * as moment from 'moment';
import { DatastoreService } from 'src/app/services/datastore.service';
import { CalendarService } from 'src/app/services/calendar.service';
import { DateAdapter } from '@angular/material/core';
import { MatCalendar } from '@angular/material/datepicker';
import { DepartmentService } from 'src/app/services/jrni/department.service';

@Component({
  selector: 'app-mat-calendar',
  templateUrl: './mat-calendar.component.html',
  styleUrls: ['./mat-calendar.component.scss']
})
export class MatCalendarComponent implements OnInit {
  minDate;
  maxDate;
  selectedDate: Date;
  currentDate: Date;
  slots: any[] = [];
  selectedSlot;
  endTime;
  availableDays: any;
  selectedLocation: any;
  @Input() service: any;
  @Input() location: any;
  @Input() component: any;
  @Input() slot: any;
  @Input() date: Date;
  @Output() selectSlotUpdate = new EventEmitter<any>();
  @ViewChild('calendar', { static: false }) calendar: MatCalendar<Date>;


  constructor(
    private availabilityService: AvailabilityService,
    private alertService: AlertService,
    public translateService: TranslateService,
    private spinner: NgxSpinnerService,
    private datastoreService: DatastoreService,
    private calendarService: CalendarService,
    private departmentService: DepartmentService,
    private dateAdapter: DateAdapter<Date>
  ) { }

  ngOnInit() {
    this.spinner.show();
    // Set the current date to today for the calendar to default to today

    this.translateService.onLangChange.subscribe(async (event) => {
      this.dateAdapter.setLocale(event.lang);
      this.prepareDates();
      await this.calendarRun();
      if (this.slot) {
        this.selectSlot(this.slot);
      }
    });

    this.calendarService.onLocationChange.subscribe(async (location) => {
      this.location = location;
      await this.calendarRun();
      if (this.slot) {
        this.selectSlot(this.slot);
      }
      this.prepareDatesAfterUpdate();
    });

    this.prepareDates();

    (async () => {
      await this.calendarRun();
      if (this.slot) {
        this.selectSlot(this.slot);
      }
    })();
  }

  prepareDates() {
    this.currentDate = new Date();
    if (!this.selectedDate) {
      this.selectedDate = this.currentDate;
    }
    if (this.date) {
      this.selectedDate = this.date;
    }

    // Set max date as yesterday so the user cannot select a date until
    const minAdvTime = new Date(this.service.min_advance_datetime);
    // back office is set to 16 minutes, we need 20 minutes so add 4 minutes onto it
    this.minDate = moment(minAdvTime).add(4, 'm').toDate();
    this.maxDate = new Date(this.service.max_advance_datetime);
  }

  prepareDatesAfterUpdate() {
    // Set max date as yesterday so the user cannot select a date until
    const minAdvTime = new Date(this.service.min_advance_datetime);
    // back office is set to 16 minutes, we need 20 minutes so add 4 minutes onto it
    this.minDate = moment(minAdvTime).add(4, 'm').toDate();
    this.maxDate = new Date(this.service.max_advance_datetime);
  }

  async calendarRun() {
    let serviceAvailability;
    if (typeof this.minDate === "undefined") {
      this.prepareDates();
    }
    // Get the available times for a service
    if (this.component) {
      serviceAvailability = await this.availabilityService.requestAvailableDays(this.location, this.service, this.minDate.toISOString().split('T')[0], this.maxDate.toISOString().split('T')[0]);
    } else {
      serviceAvailability = await this.availabilityService.requestAvailableDaysService(this.location, this.service, this.minDate.toISOString().split('T')[0], this.maxDate.toISOString().split('T')[0]);
    }

    // Set the available times so we do have to keep sending the same request
    await this.availabilityService.setAvailableDays(serviceAvailability.days);

    // If service availability contains data then filter the days that are available
    if (serviceAvailability.days && serviceAvailability.days.length > 0) {
      // Get the available days
      this.availableDays = serviceAvailability.days.filter(day => day.spaces > 0);
    } else {
      this.alertService.error(this.translateService.instant('COMMON.NO_AVAILABILITY'));
    }

    // If the selected date has been store in datastore then pre populate the selection
    if (this.datastoreService.selectedDate) {
      let slotDate = new Date();
      if (this.datastoreService.selectedSlot.hasOwnProperty('start')) {
        slotDate = this.datastoreService.selectedSlot.start;
      }
      else {
        slotDate = this.datastoreService.selectedSlot
      }
      await this.updateSlots(new Date(slotDate));
      this.selectedSlot = this.datastoreService.selectedSlot;
    } else {
      if (this.availableDays && this.availableDays.length > 0) {
        await this.updateSlots(new Date(this.availableDays[0].date));
      } else {
        await this.updateSlots(new Date());
      }
    }
    if(this.slots.length > 0) {
      this.selectSlot(this.slots[0]);
    }
    this.spinner.hide();
  }

  // Check if the date is unavailable and if so return false
  unavailableDates = (date: Date): boolean => {
    const momentDate = moment(date);
    if (this.availableDays) {
      return this.availableDays.some(day => momentDate.isSame(day.date));
    } else {
      return false;
    }
  }

  // Date selected on calendar
  async updateSlots(date) {
    // add two hours onto the date so when we convert with iso it always keeps the same date
    date.setHours(date.getHours() + 2);

    // Update the selected date
    this.selectedDate = date;

    // slice time off
    const isoTime = date.toISOString().slice(0, -1).split('T')[0];

    const params = {
      'service_id': this.service.id,
      'start_date': isoTime,
      'end_date': isoTime,
    };

    let wheelChairAccess = false;

    if (this.datastoreService.companyQuestionsForm) {
      wheelChairAccess = this.datastoreService.companyQuestionsForm.controls[277].value.toLowerCase().includes("yes") ? true : false;
    }

    // Only add slots with availability
    const res = await this.availabilityService.getSlots(this.location, params);
    this.slots = [];
    if (res) {
      if (wheelChairAccess) {
        // @TODO - rework this using a slot call per resource
        // const wcLocations = this.departmentService.getAccessibleLocations();
        // for (let staff of res['_embedded'].events) {
        //   let staffEvents = [];
        //   if (wcLocations.length > 0) {
        //     for (let l of wcLocations) {
        //       if (l.id == staff.resource_id) {
        //         staffEvents = staff.times;
        //       }
        //     }
        //   }
        //   staff.times = staffEvents;
        // }
      }

      let setCalendarMonth = false;
      for(let time of res['times']) {
        if(time.available) {
          if (!setCalendarMonth) {   
            setCalendarMonth = true;    
            this.calendar._goToDateInView(this.selectedDate, 'month');
          }
          this.slots.push(time);
        }
      }
      this.slots.sort((a, b) => a.time - b.time)
      
    }

    // Remove dupes from different staff
    this.slots = this.slots.filter((slot, index, self) =>
      index === self.findIndex((t) => (
        t.start === slot.start
      ))
    )

    // Remove slots if they are past the minDate (20 minutes before any booking)
    this.slots = this.slots.filter(x => new Date(x.start) > this.minDate);
  }

  // Set the slot
  selectSlot(slot) {
    this.selectedSlot = slot;
    this.availabilityService.setSelectedSlot(slot);
    this.selectSlotUpdate.emit(slot);
    // set end slot time
    var endSlot = slot.start;
    this.endTime = moment(endSlot).add(30, 'm').toDate();
    this.availabilityService.setEndSlot(this.endTime)
  }
}



