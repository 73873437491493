import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { MemberService } from "src/app/services/jrni/member.service";
import { DepartmentService } from "src/app/services/jrni/department.service";
import { Router } from "@angular/router";
import { AlertService } from "src/app/_alert";
import { ServicesService } from "src/app/services/jrni/services.service";
import { TranslateService } from "@ngx-translate/core";
import { NgxSpinnerService } from "ngx-spinner";
import { DatastoreService } from "src/app/services/datastore.service";
import { AvailabilityService } from "src/app/services/jrni/availability.service";
import { AppConfig } from "src/app/app.config";
import { QuestionsService } from "src/app/services/questions.service";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";

@Component({
  selector: "app-services",
  templateUrl: "./services.component.html",
  styleUrls: ["./services.component.scss"],
})
export class ServicesComponent implements OnInit {
  selectedDepartment;
  pageTitle;
  servicePageText;
  servicesList;
  selectedService;
  companyQuestions = [];
  private customerQuestions = AppConfig.settings.customerQuestions;
  private services = AppConfig.settings.services;
  location;
  guidanceText;
  submitted: boolean = false;
  showServices = false;
  answeredQuestions = [false, false, false];
  ageType = "";
  compQuestions = [];

  constructor(
    private departmentService: DepartmentService,
    private router: Router,
    protected alertService: AlertService,
    private servicesService: ServicesService,
    private cdref: ChangeDetectorRef,
    private spinner: NgxSpinnerService,
    private datastoreService: DatastoreService,
    private memberService: MemberService,
    private availabilityService: AvailabilityService,
    private questionsService: QuestionsService,
    private formBuilder: FormBuilder,
    public translateService: TranslateService,
    ) { }

  companyQuestionsForm: FormGroup = this.formBuilder.group({});

  get form() {
    return this.companyQuestionsForm.controls;
  }

  // Go back to the booking details page
  routerGoBack() {
    // If location is before services then redirect back to locations else redirect back to departments
    this.router.navigate(["/departments"]);
  }

  restartJourney() {
    this.router.navigate(["/departments"]);
  }

  async ngOnInit() {
    this.datastoreService.bookingForm = null;
    this.datastoreService.companyQuestionsForm = null;
    this.datastoreService.selectedDate = null;
    this.datastoreService.selectedSlot = null;
    this.availabilityService.setSelectedSlot(null);
    this.scrollToTop();
    this.spinner.show();
    
    // Use this.departmentService.getLocation() if the services are set on the children departments
    // Use this.departmentService.get() if the services are set on the parent department
    this.departmentService.get().then(
      (selectedDepartment) => {
        if (selectedDepartment === undefined) {
          this.restartJourney();
        } else {
          this.selectedDepartment = selectedDepartment;

          this.getCompanyQuestions()

          this.selectedDepartment.$getCompanyQuestions().then((res) => {
            this.compQuestions = [];
            for(let i in res){
              if(res[i].id === 230 || res[i].id === 231){
                this.compQuestions.push(res[i])
              }
            }
          })

          // Get the configurable data
          if (this.selectedDepartment.hasOwnProperty("extra")) {
            // Set the extra text on the page to the text taken from the business question
            this.selectedDepartment.extra.hasOwnProperty("services_page_text")
              ? (this.servicePageText = this.selectedDepartment.extra.services_page_text)
              : null;
            this.selectedDepartment.extra.hasOwnProperty("service_guidance_text")
              ? (this.guidanceText = this.selectedDepartment.extra.service_guidance_text)
              : null;
          }

          // Get the available services on the selected department
          this.servicesService
            .getServices(selectedDepartment)
            .then((services) => {
              this.memberService.getMember().then(
                (member) => {
                  this.servicesList = services;
                  services.forEach((service, index) => {
                    service.$getImages().then((image) => {
                      service.image = image[0];
                      this.cdref.detectChanges();
                    });
                  });
                  this.spinner.hide();
                },
                (err) => {
                  this.alertService.error(
                    this.translateService.instant("COMMON.GENERAL_ERR")
                  );
                  this.spinner.hide();
                }
              );
            });
        }
      },
      (err) => {
        this.alertService.error(
          this.translateService.instant("COMMON.GENERAL_ERR")
        );
        this.spinner.hide();
      }
    );
  }

  validateQuestions(event, question) {
    if(question.id === 277) {
      if(event.target.selectedIndex > 0) {
        this.answeredQuestions[0] = true;
      }
      else {
        this.answeredQuestions[0] = false;
      }
    }
    if(question.id === 276) {
      if(event.target.selectedIndex > 0) {
        this.answeredQuestions[1] = true;
      }
      else {
        this.answeredQuestions[1] = false;
      }
    }
    if(question.id === 278) {
      if(event.source._checked === true) {
        this.answeredQuestions[2] = true;
        this.ageType = event.source._value;
      }
      else {
        this.answeredQuestions[2] = false;
      }
    }
    this.calculateVisibility();
  }

  calculateVisibility() {
    let trueCount = 0;
    for(const item of this.answeredQuestions) {
      if(item) {
        trueCount++;
      }
    }
    if(trueCount > 2) {
      this.showServices = true;
    }
    else {
      this.showServices = false;
    }
  }

  selectService(service) {
    this.selectedService = service;

    this.submitted = true;

    if (this.companyQuestionsForm.invalid) {
      this.scrollToTop();
      this.spinner.hide();
      this.alertService.warn(this.translateService.instant('FORM.VALIDATION_ERR_MSG'));
      return;
    }

    this.datastoreService.companyQuestionsForm = this.companyQuestionsForm;

    this.continue();
  }

  continue() {
    if (this.selectedService) {
      this.servicesService.set(this.selectedService);
      // backup the service so taht, if we switch to the alt company, we have a way to swtich back
      this.datastoreService.selectedService = this.selectedService; 
      this.router.navigate(["/locations"]);
    } else {
      this.alertService.warn("Please select a service before proceeding");

      this.scrollToTop();
    }
  }

  // scroll to the top of the page
  scrollToTop() {
    window.scroll(0, 0);
  }

  async getCompanyQuestions() {
    this.questionsService.getCompanyQuestions().subscribe((res: any) => {
      // Questions = all questions on the company
      const questions = res.questions;
      this.datastoreService.companyQuestions = questions;
      this.addQuestionsToForm(questions)
    });
  }

  addQuestionsToForm(questions) {
    for (let index = 0; index < questions.length; index++) {
      const question = questions[index];
      question.visible = false;
      question.reference = question.id;
      if (!question.admin_only) {
        if (!question.settings.hasOwnProperty("conditional_question")) {
          question.visible = true;
        }
        const questionControl = question.id;

        if ( question.id === 277 || question.id === 276 || question.id === 278) {
          this.companyQuestions.push(question);
          if (question.detail_type == 'check' || question.detail_type == 'check-price') {
            if (question.required) {
              this.companyQuestionsForm.addControl(questionControl, new FormControl(false, Validators.requiredTrue));
            } else {
              this.companyQuestionsForm.addControl(questionControl, new FormControl(false));
            }
          } else {
            if (question.required) {
              this.companyQuestionsForm.addControl(questionControl, new FormControl('', Validators.required));
            } else {
              this.companyQuestionsForm.addControl(questionControl, new FormControl(''));
            }
          }
        }
      }
    }
  }
}