import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, empty, TimeoutError } from 'rxjs';
import { timeout, map, catchError } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { DatastoreService } from './datastore.service';

@Injectable()
export class HttpInterceptorInterceptor implements HttpInterceptor {
  loadIssue;

  constructor(
    private DataStore: DatastoreService,
    private spinner: NgxSpinnerService,
  ) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      timeout(30000),
      map(res => {
        return res;
      }),
      catchError(err => {
        console.log(err);
        this.DataStore.onNotify(true)
        this.spinner.hide();
        if (err instanceof TimeoutError) {
          console.error('Timeout has occurred', request.url);
        } return empty();
      })
    );
  }
}
