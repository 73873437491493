import { Component, OnInit } from '@angular/core';
import { DepartmentService } from 'src/app/services/jrni/department.service';
import { AlertService } from 'src/app/_alert';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { LoginService } from 'src/app/services/jrni/login.service';
import { HttpErrorResponse } from '@angular/common/http';
import { MemberService } from 'src/app/services/jrni/member.service';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { BBModel, halClient } from 'bookingbug-core-js';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  selectedDepartment;
  pageTitle: string;
  emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
  submitted: boolean;
  loginPageText: string;
  resetPasswordField: boolean;
  resetLoginSubmitted: boolean;

  constructor(
    private memberService: MemberService,
    private departmentService: DepartmentService,
    private loginService: LoginService,
    protected alertService: AlertService,
    private router: Router,
    private translateService: TranslateService,
    private spinner: NgxSpinnerService
  ) { }

  // Define the login form
  loginForm: FormGroup = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.pattern(this.emailRegex)]),
    password: new FormControl('', Validators.required)
  });

  // Define the reset password form
  resetPasswordForm: FormGroup = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.pattern(this.emailRegex)]),
  });

  // Getter for easy access to form fields
  get form() { return this.loginForm.controls; }

  // Getter for easy access to form fields
  get resetForm() { return this.resetPasswordForm.controls; }

  // Go back to the booking details page
  routerGoBack() {
    this.router.navigate(['/departments']);
  }

  ngOnInit() {
    this.scrollToTop();
    this.spinner.show();
    // Use this.departmentService.getLocation() if the services are set on the children departments
    // Use this.departmentService.get() if the services are set on the parent department
    this.departmentService.get().then(selectedDepartment => {
      if (selectedDepartment === undefined) {
        this.routerGoBack();
      } else {
        this.memberExists();

        this.selectedDepartment = selectedDepartment;

        if (this.selectedDepartment.hasOwnProperty('extra')) {
          // Set the title on the login page to the title from the business question
          this.selectedDepartment.extra.hasOwnProperty('login_page_title') ? this.pageTitle = this.selectedDepartment.extra.login_page_title : this.pageTitle = "Login title not set";
          // Set the extra text on the page to the text taken from the business question
          this.selectedDepartment.extra.hasOwnProperty('login_page_text') ? this.loginPageText = this.selectedDepartment.extra.login_page_text : null;
        }
        this.spinner.hide();
      }
    }, err => {
      this.alertService.error(this.translateService.instant('COMMON.GENERAL_ERR'))
      this.spinner.hide();
    });
  }

  memberExists() {
    this.memberService.getMember().then(async member => {
      if (member) {
        this.router.navigate(['/services']);
      }
      if (sessionStorage.getItem("login")) {
        // Get the login stored in the session storage
        const login = JSON.parse(sessionStorage.getItem('login'));
        // Create a member out of the object
        const member = new BBModel.Admin.User(halClient.createResource(login));
        this.memberService.setMember(member);
        this.router.navigate(['/services']);
      }
    })
  }

  forgottenPassword() {
    !this.resetPasswordField ? this.resetPasswordField = true : this.resetPasswordField = false;
  }

  resetPassword() {
    this.resetLoginSubmitted = true;
    if (this.resetPasswordForm.invalid) {
      return;
    } else {
      this.loginService.resetPassword(this.selectedDepartment, this.resetPasswordForm.value).then(res => {
        this.alertService.success("If email exists as a user you should receive an email with a new password.")
      }).catch((error: HttpErrorResponse) => {
        if (error['response'] && error['response'].status === 404) {
          this.alertService.error("Failed to reset password. User not found")
        } else {
          this.alertService.error("Failed to reset password.")
        }
      });
    }
  }

  register() {
    this.router.navigate(['/register']);
  }

  onSubmit() {
    this.submitted = true;
    // stop here if the login form is invalid
    if (this.loginForm.invalid) {
      return;
    } else {
      this.loginService.loginMember(this.selectedDepartment, this.loginForm.value).then(async res => {
        await this.memberService.setMember(res);


        this.router.navigate(['/services']);

      })
        .catch((error: HttpErrorResponse) => {
          if (error || error['response'] && error['response'].status === 401) {
            this.alertService.error(this.translateService.instant('LOGIN.EMAIL_NOT_RECOGNISED'))
          }
        });
    }
  }

  // scroll to the top of the page
  scrollToTop() {
    window.scroll(0, 0);
  }
}
