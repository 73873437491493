import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { BookingService } from '../services/jrni/booking.service';
import { AlertService } from '../_alert';
import { AvailabilityService } from '../services/jrni/availability.service';
import * as moment from 'moment';

interface DialogData {
  booking,
  location,
  action,
  service,
  member,
  header
}

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {
  cancelMessage = true;
  cancelPromptMessage = false;
  cancelPassed: boolean;
  modifyPassed: boolean;
  slotEmpty = false;
  selectedSlot: Date;
  selectedSlotPersonId: any;

  constructor(
    public dialogRef: MatDialogRef<ModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private bookingService: BookingService,
    private alertService: AlertService,
    private translateService: TranslateService,
    private spinner: NgxSpinnerService,
    private availabilityService: AvailabilityService
  ) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    if (this.data['booking'].canCancel()) {
      this.cancelPassed = false;
      this.modifyPassed = false;
    } else {
      this.cancelMessage = false;
      this.cancelPassed = true;
      this.modifyPassed = true;
    }
  }

  continue() {
    this.cancelMessage ? this.cancelMessage = false : this.cancelMessage = true;
    this.cancelPromptMessage ? this.cancelPromptMessage = false : this.cancelPromptMessage = true;
  }

  cancelBooking(member, booking) {
    this.bookingService.cancelBooking(member, booking).then(res => {
      this.dialogRef.close();
      this.alertService.success(this.translateService.instant('ACCOUNT.CANCEL_SUCCESS'))
    }).catch((error: HttpErrorResponse) => {
      this.dialogRef.close();
      this.alertService.error(this.translateService.instant('ACCOUNT.CANCEL_FAILED'))
      this.spinner.hide();
    });
  }

  modifyBooking(member, booking) {
    this.alertService.clear();
    this.bookingService.cancelBooking(member, booking).then(res => {
      this.dialogRef.close();
      this.alertService.success(this.translateService.instant('ACCOUNT.CANCEL_SUCCESS'))
    }).catch((error: HttpErrorResponse) => {
      this.dialogRef.close();
      this.alertService.error(this.translateService.instant('ACCOUNT.CANCEL_FAILED'))
      this.spinner.hide();
    });
  }

  selectedSlotCheck(slotUpdated: boolean) {
    if (slotUpdated) {
      this.availabilityService.getSelectedSlot().then(slot => {
        if (slot) {
          this.slotEmpty = false;
          this.selectedSlot = slot.start;
          this.selectedSlotPersonId = slot;
        } else {
          this.slotEmpty = true;
        }
      });
    }
  }

  // Reschedule the booking
  confirmAmend(booking, slot, member) {
    const personId = this.selectedSlotPersonId.person_id;
    this.alertService.clear();
    const originalDateTime = booking.datetime;
    this.spinner.show();
    if (slot) {
      booking.datetime = moment(slot);
      this.slotEmpty = false;
      this.bookingService.moveBooking(booking, member, slot, personId).then(booking => {
        this.alertService.success(this.translateService.instant('ACCOUNT.MODIFY_SUCCESS'))
        this.spinner.hide();
        this.dialogRef.close();
      }).catch((error: HttpErrorResponse) => {
        booking.datetime = originalDateTime;
        this.dialogRef.close();
        this.spinner.hide();
        this.alertService.error(this.translateService.instant('ACCOUNT.MODIFY_FAILED'))
      });
    } else {
      this.slotEmpty = true;
      return;
    }
  }
}
