import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { DatastoreService } from 'src/app/services/datastore.service';
import { DepartmentService } from 'src/app/services/jrni/department.service';
import { AppConfig } from "src/app/app.config";

@Injectable({
  providedIn: "root",
})
export class AvailabilityService {
  private core = AppConfig.settings.core;
  private apiServer = AppConfig.settings.apiServer;
  selectedSlot;
  selectedResource;
  availableTimesService;
  availableDaysService;
  endSlot;

  constructor(
    private http: HttpClient,
    private datastoreService: DatastoreService,
    private departmentService: DepartmentService
    ) { }

  async getSlots(location, params) {
    const headers: HttpHeaders = new HttpHeaders({
      "App-Id": this.core.app_id,
      "App-Key": this.core.app_key,
    });

    let wheelChairAccess = false;

    if (this.datastoreService.companyQuestionsForm) {
      wheelChairAccess = this.datastoreService.companyQuestionsForm.controls[277].value.toLowerCase().includes("yes") ? true : false;
    }

    const apiUrl = `${this.apiServer.api_url}/api/v5/${location.id}/times`;

    // Only load resources with wheelchair access
    if (wheelChairAccess) {
      const resources = await this.departmentService.getAccessibleLocations();
      if (resources.length > 0) {
        for (const resource of resources) {
          if (resource.company_id == location.id) {
            if (resource.extra?.wheelchair_access && resource.extra?.wheelchair_access == 'Yes') {
              params['resource_id'] = resource.id;
            }
          }
        }
      }
    }
    
    const data = await this.http.get(apiUrl, { headers, params }).toPromise();
    return data;
  }

  async setSelectedSlot(slot) {
    this.selectedSlot = slot;
  }
  async setEndSlot(slot) {
    this.endSlot = slot;
  }

  async getSelectedSlot() {
    return this.selectedSlot;
  }
  async getEndSlot() {
    return this.endSlot;
  }
  async getSelectedResource() {
    return this.selectedResource;
  }

  async requestAvailibleTimes(location, service, today, maxDate) {
    const params = {
      company_id: location.id,
      service_id: service.id,
      start_date: today,
      end_date: maxDate,
    };
    const baseResource = await location.$get("times", params);
    const slots = await baseResource.$get("times");
    return slots;
  }

  async setAvailableTimes(availableTimes) {
    this.availableTimesService = availableTimes;
  }

  async getTimes() {
    return this.availableTimesService;
  }

  async requestAvailableDays(location, service, today, maxDate) {
    // Use below if the services are set on the parent department
    const services = await service.$getChildServices();
    const childService = services.filter(service => service.company_id === location.id);
    return await childService[0].$get('days', { company_id: location.id, date: today, edate: maxDate });

    // Use below if the services are set on the children departments
    // return await service.$get("days", {
    //   company_id: location.id,
    //   date: today,
    //   edate: maxDate,
    // });
  }

  async setAvailableDays(availableDays) {
    this.availableDaysService = availableDays;
  }

  async getDays() {
    return this.availableDaysService;
  }

  async requestAvailableDaysService(location, service, today, maxDate) {
    return await service.$get("days", {
      company_id: location.id,
      date: today,
      edate: maxDate,
    });
  }
}
