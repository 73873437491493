import { Injectable } from '@angular/core';
import { AppConfig } from 'src/app/app.config';
import { DepartmentService } from './department.service';

@Injectable({
  providedIn: 'root'
})
export class ServicesService {
  selectedService;

  constructor(
  ) { }

  async getServices(department) {
    return await department.$getServices();
  }

  async set(service) {
    this.selectedService = service;
  }

  async get() {
    return this.selectedService;
  }
}
