import { Component, OnInit } from '@angular/core';
import { BBModel, halClient } from 'bookingbug-core-js';
import { MemberService } from 'src/app/services/jrni/member.service';

@Component({
  selector: 'app-main-account',
  templateUrl: './main-account.component.html',
  styleUrls: ['./main-account.component.scss']
})
export class MainAccountComponent implements OnInit {
  accountLinks = [];
  selectedOption: String;
  pageTitle: String;
  member;


  constructor(
    private memberService: MemberService,
  ) { }

  async ngOnInit() {
    const login = JSON.parse(sessionStorage.getItem('login'));

    const member = new BBModel.Admin.User(halClient.createResource(login));
    this.memberService.setMember(member);

    this.accountLinks.push("Account");
    this.accountLinks.push("Change password");
    this.accountLinks.push("Upcoming bookings");
    this.accountLinks.push("Past bookings");

    this.selectedOption = "Account";
    this.pageTitle = this.selectedOption;
  }

  onSelect(option) {
    this.selectedOption = option;
    this.pageTitle = option;
  }
}
