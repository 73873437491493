import { Injectable } from '@angular/core';
import { BBModel } from 'bookingbug-core-js';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfig } from 'src/app/app.config';

@Injectable({
  providedIn: 'root'
})
export class BookingService {
  private core = AppConfig.settings.core;
  private apiServer = AppConfig.settings.apiServer;

  constructor(
    private http: HttpClient
  ) { }

  async cancelBooking(member, booking) {
    return await BBModel.Member.Booking.$cancel(member, booking);
  }

  async updateBooking(booking) {
    return await BBModel.Member.Booking.$update(booking);
  }

  async rescheduleBooking(booking, member, slot) {
    const headers: HttpHeaders = new HttpHeaders({
      'App-Id': this.core.app_id,
      'App-Key': this.core.app_key,
      'Auth-Token': sessionStorage.getItem("auth_token")
    });
    const params = {
      date: booking.datetime.format("YYYY-MM-DD"),
      time: booking.datetime.format("HH:mm"),
    }
    const apiUrl = `${this.apiServer.api_url}/api/v4/${booking.company_id}/members/${member.id}/bookings/${booking.id}/reschedule`;
    return await this.http.put(apiUrl, params, { headers }).toPromise();
  }

  async moveBooking(booking, member, slot, person_id) {
    const datetime = new Date(slot);
    
    const headers: HttpHeaders = new HttpHeaders({
      'App-Id': this.core.app_id,
      'App-Key': this.core.app_key,
      'Auth-Token': sessionStorage.getItem("auth_token")
    });

    const apiUrl = `/westsussexMoveBooking/${booking.company_id}/members/${member.id}/bookings/${booking.id}`;
    return await this.http.put(apiUrl, {
      date: datetime.toISOString().split('T')[0],
      time: datetime.getMinutes() + (datetime.getHours() * 60),
      notify: true,
      person_id
    }, { headers }).toPromise();
  }
}
