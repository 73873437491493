import { Injectable } from '@angular/core';
import { BBService } from 'bookingbug-core-js';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DatastoreService {

  constructor() { }

  public bookingQuestions = [];

  public unselectedLocations = []
  
  public bookingForm;

  public selectedDate;

  public selectedSlot;

  public selectedResource;

  public selectedService; //used only to store the service incase we switch to the alt one
  public selectedDepartment; //used only to store the service incase we switch to the alt one

  public locations = [];

  public bookingAnswers;

  public customerDetailsForm;

  public client;

  public address;

  public latitude;

  public longitude;

  public endSlot;

  public customerQuestions;

  public companyQuestions;

  public address1

  public companyQuestionsForm;

  notify: Subject<boolean> = new Subject<boolean>();
  onNotify(event) {
    this.notify.next(true);
  }

}
