import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfig } from "src/app/app.config";

@Injectable({
  providedIn: 'root'
})
export class QuestionsService {
  private core = AppConfig.settings.core;
  private apiServer = AppConfig.settings.apiServer;
  constructor(private http: HttpClient) { }

  getCompanyQuestions() {
    const headers: HttpHeaders = new HttpHeaders({
      "App-Id": this.core.app_id,
      "App-Key": this.core.app_key,
    });

    // This is hardcoded as it's the ID in the back office
    const detail_group_id = 18638;
    const apiUrl = `${this.apiServer.api_url}/api/v5/${this.core.company_id}/questions?detail_group_id=${detail_group_id}`;
    
    return this.http.get(apiUrl, { headers });
  }

  getAltCompanyQuestions() {
    const headers: HttpHeaders = new HttpHeaders({
      "App-Id": this.core.app_id,
      "App-Key": this.core.app_key,
    });

    // This is hardcoded as it's the ID in the back office
    const detail_group_id = 18550
    const apiUrl = `${this.apiServer.api_url}/api/v5/${this.core.alt_company_id}/questions?detail_group_id=${detail_group_id}`;
    
    return this.http.get(apiUrl, { headers });
  }
}
