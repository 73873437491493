import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, ValidatorFn, Validators, AbstractControl } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ClientService } from 'src/app/services/jrni/client.service';
import { DepartmentService } from 'src/app/services/jrni/department.service';
import { AlertService } from 'src/app/_alert';
import { MemberService } from 'src/app/services/jrni/member.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { LoginService } from 'src/app/services/jrni/login.service';
import { AppConfig } from 'src/app/app.config';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  selectedDepartment;
  pageTitle: string;
  registerPageText: string;
  customerQuestions = [];
  emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
  submitted: boolean;
  requiresFullRegistration = false;
  allowsMembers: boolean;
  bookingQuestionsConfig = AppConfig.settings.bookingQuestions;

  constructor(
    private departmentService: DepartmentService,
    private router: Router,
    private alertService: AlertService,
    private translateService: TranslateService,
    private clientService: ClientService,
    private memberService: MemberService,
    private spinner: NgxSpinnerService,
    private loginService: LoginService
  ) { }

  // Initialise the form
  customerForm: FormGroup = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.pattern(this.emailRegex)])
  });

  // Getter for easy access to form fields
  get form() { return this.customerForm.controls; }

  routerGoBack() {
    this.router.navigate(['/login']);
  }

  restartJourney() {
    this.router.navigate(['/departments']);
  }

  ngOnInit() {
    this.scrollToTop();
    this.spinner.show();
    // Use this.departmentService.getLocation() if the services are set on the children departments
    // Use this.departmentService.get() if the services are set on the parent department
    this.departmentService.get().then(selectedDepartment => {
      if (selectedDepartment === undefined) {
        this.restartJourney();
      } else {
        this.selectedDepartment = selectedDepartment;
        let registrationOption;

        // Get the configurable data
        if (this.selectedDepartment.hasOwnProperty('extra')) {
          // Set the title on the login page to the title from the business question
          this.selectedDepartment.extra.hasOwnProperty('registration_page_title') ? this.pageTitle = this.selectedDepartment.extra.registration_page_title : this.pageTitle = "Registration title not set";
          // Set the extra text on the page to the text taken from the business question
          this.selectedDepartment.extra.hasOwnProperty('registration_page_text') ? this.registerPageText = this.selectedDepartment.extra.registration_page_text : null;
          // Get the registration option
          this.selectedDepartment.extra.hasOwnProperty('registration_option') ? registrationOption = this.selectedDepartment.extra.registration_option : null;
        }

        // If the department takes members then add a password field to the form
        if (this.selectedDepartment.$has('login')) {
          this.allowsMembers = true;
          this.customerForm.addControl('password', new FormControl('', Validators.compose([Validators.required])));
          //this.customerForm.addControl('confirm_password', new FormControl('', Validators.compose([Validators.required])));
        }

        // If the register option is set to complete a full registration then capture the following details
        if (registrationOption.toLowerCase() === 'register complete details') {
          this.requiresFullRegistration = true;
          this.customerForm.addControl('first_name', new FormControl('', Validators.required));
          this.customerForm.addControl('last_name', new FormControl('', Validators.required));
          this.customerForm.addControl('mobile', new FormControl('', [Validators.required, Validators.minLength(5)]));
          this.customerForm.addControl('address1', new FormControl(''));
          this.customerForm.addControl('address2', new FormControl(''));
          this.customerForm.addControl('address4', new FormControl(''));
          this.customerForm.addControl('country', new FormControl(''));
          this.customerForm.addControl('postcode', new FormControl(''));
        }

        // Get the customer questions
        this.departmentService.getCustomerQuestions().then(questions => {
          this.customerQuestions = questions;

          for (let index = 0; index < this.customerQuestions.length; index++) {
            const question = this.customerQuestions[index];
            question.visible = false;
            question.reference = question.id;
            if (!question.admin_only) {
              if (!question.settings.hasOwnProperty('conditional_question')) {
                question.visible = true;
              }

              const questionControl = question.id;
              if (question.default == 1 && question.detail_type == 'check' || question.default == 1 && question.detail_type == 'check-price') {
                if (question.required) {
                  this.customerForm.addControl(questionControl, new FormControl(true, Validators.required));
                } else {
                  this.customerForm.addControl(questionControl, new FormControl(true));
                }
              } else {
                if (question.required) {
                  this.customerForm.addControl(questionControl, new FormControl('', Validators.required));
                } else {
                  this.customerForm.addControl(questionControl, new FormControl(''));
                }
              }
            }
          }
          this.spinner.hide();
        }, err => {
          this.alertService.error(this.translateService.instant('COMMON.GENERAL_ERR'));
          this.spinner.hide();
        });
      }
    });
  }

  onQuestionChange() {
    const customerQuestions = this.customerQuestions
    // Conditional questions logic
    for (let index = 0; index < customerQuestions.length; index++) {
      const q = customerQuestions[index];
      // If the question has settings and relies on a conditional question
      if (q.settings && q.settings.hasOwnProperty('conditional_question')) {
        // Get the conditional question based on the id set in q
        const conditionalQ = customerQuestions.filter(question => question.id == q.settings.conditional_question);
        // Get the question control
        const questionControl = this.form[q.settings.conditional_question];
        // If the question control has a value get the option from the contional question. This will allow
        // us to check if q is shown when value is selected
        const option = conditionalQ[0].options.filter(option => option.name == questionControl.value);

        // If the conditional question is visible and the value chosen === to qs conditional answer then show q
        if (conditionalQ.length > 0 && conditionalQ[0].visible && option.length > 0 && q.settings.conditional_answers.hasOwnProperty(option[0].id)) {
          this.form[q.id].enable();
          // If the condiditonal question is important, Hide it
          if (q.important) {
            q.visible = false;
          } else {
            q.visible = true;
          }
        } else {
          q.visible = false;
          this.form[q.id].setValue('');
          this.form[q.id].disable();
        }
      }
    }
  }

  async onSubmit() {
    this.customerForm.markAllAsTouched();
    this.submitted = true;

    for (let index = 0; index < this.customerQuestions.length; index++) {
      const question = this.customerQuestions[index];
      if (question.id === this.bookingQuestionsConfig.schoolNameSelectionAccounts) {
        const test = question.options.filter(option => option.name == this.customerForm.value[this.bookingQuestionsConfig.schoolNameSelectionAccounts]);
        test.length > 0 ? this.customerForm.controls[this.bookingQuestionsConfig.schoolNameSelectionAccounts].setErrors(null) : this.customerForm.controls[this.bookingQuestionsConfig.schoolNameSelectionAccounts].setErrors({ 'selectedSchoolErr': "Please select a school from the list" });
      }
    }

    if (this.customerForm.invalid) {
      this.alertService.warn(this.translateService.instant('FORM.VALIDATION_ERR_MSG'));
      this.scrollToTop();
      return;
    } else {
      if (!this.requiresFullRegistration) {
        // Get the question that will act as the last name of the client
        this.customerForm.value.last_name = this.form[this.selectedDepartment.extra.registration_question_name].value;
      }
      // Set the answers on the customer form being passed in as the client
      this.customerForm.value.questions = [];

      // For each question in the form check if the customer question exists and if so add to customerAnswers []
      for (const control in this.form) {
        if (this.form.hasOwnProperty(control)) {
          const element = this.form[control];
          const question = this.customerQuestions.filter(question => question.id.toString() === control);

          if (question.length > 0 && element.value.length > 0) {
            // Set a question object
            const q = {
              id: question[0].id,
              answer: element.value
            }
            this.customerForm.value.questions.push(q);
          }
        }
      }
      // Create client
      const clientExists = await this.clientService.create(this.selectedDepartment, this.customerForm.value);

      if (clientExists && clientExists.auth_token) {
        await this.memberService.setMember(clientExists);
        this.loginService.setLogin(clientExists, null);

        this.router.navigate(['/services']);

      } else if (clientExists && clientExists.response.status == 401) {
        this.alertService.warn(this.translateService.instant('REGISTRATION.ACCOUNT_EXISTS', { email: this.customerForm.value.email }));
        this.scrollToTop();
      } else {
        this.alertService.error(this.translateService.instant('COMMON.GENERAL_ERR'));
        this.scrollToTop();
      }
    }
  }

  // scroll to the top of the page
  scrollToTop() {
    window.scroll(0, 0);
  }

  // Number input text validation, keycode 8 is backspace
  keyPressNumberField(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
}