import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-skip-to-content',
  templateUrl: './skip-to-content.component.html',
  styleUrls: ['./skip-to-content.component.css']
})
export class SkipToContentComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  skipToMainContent() {
    var element = document.getElementById('main-content');
    if (element) {
      if (!/^(?:a|select|input|button|textarea)$/i.test(element.tagName)) {
        element.tabIndex = -1;
      }
      element.focus();
    }
  }
}
