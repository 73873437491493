import { Component, OnInit } from '@angular/core';
import { DepartmentService } from 'src/app/services/jrni/department.service';
import { AlertService } from 'src/app/_alert';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { DatastoreService } from 'src/app/services/datastore.service';
import { CalendarService } from 'src/app/services/calendar.service';
@Component({
  selector: 'app-departments',
  templateUrl: './departments.component.html',
  styleUrls: ['./departments.component.scss']
})
export class DepartmentsComponent implements OnInit {

  childrenDepartments: [];

  constructor(
    private departmentService: DepartmentService,
    protected alertService: AlertService,
    private router: Router,
    private translateService: TranslateService,
    private spinner: NgxSpinnerService,
    private datastoreService: DatastoreService,
    private calendarService: CalendarService
  ) { }

  ngOnInit() {
    this.scrollToTop();
    this.spinner.show();

    // clear data on slots
    this.calendarService.currentlySelectedSlot = null;
    this.calendarService.savedLocations = null;
    this.calendarService.unselectedLocations = null;
    this.datastoreService.unselectedLocations = [];

    this.departmentService.getParentDepartment().then(res => {
      this.departmentService.set(res);
      this.spinner.hide();
      this.router.navigate(['/services']);
    }, err => {
      this.alertService.error(this.translateService.instant('COMMON.GENERAL_ERR'));
      this.spinner.hide();
    });
  }

  selectDepartment(department) {
    this.departmentService.set(department);
    // If the department has the setting required login then navigate to login else to services page
    if (department.hasOwnProperty('extra') && department.extra.hasOwnProperty('login_required')) {
      if (department.extra.login_required.toLowerCase() === 'yes') {
        this.router.navigate(['/login']);
      } else {
        if (department.extra.service_or_location_first.toLowerCase() === 'service') {
          this.router.navigate(['/services']);
        }
      }
    }
  }

  // scroll to the top of the page
  scrollToTop() {
    window.scroll(0, 0);
  }
}
