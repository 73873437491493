import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppConfig } from 'src/app/app.config';
import { DepartmentService } from 'src/app/services/jrni/department.service';
import { MemberService } from 'src/app/services/jrni/member.service';
import { AlertService } from 'src/app/_alert';

@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss']
})
export class MyAccountComponent implements OnInit {
  member;
  customerQuestions;
  answers;
  registrationOption = false;
  submitted: boolean;
  bookingQuestionsConfig = AppConfig.settings.bookingQuestions;

  constructor(
    private memberService: MemberService,
    private departmentService: DepartmentService,
    private spinner: NgxSpinnerService,
    private alertService: AlertService,
    private translateService: TranslateService
  ) { }

  // Initialise the form
  memberDetailsForm: FormGroup = new FormGroup({
    first_name: new FormControl('', Validators.required),
    last_name: new FormControl('', Validators.required),
    mobile: new FormControl('', [Validators.required, Validators.minLength(5)]),
    address1: new FormControl(''),
    address2: new FormControl(''),
    address4: new FormControl(''),
    country: new FormControl(''),
    postcode: new FormControl(''),
    email: new FormControl('', Validators.required)
  });

  get form() { return this.memberDetailsForm.controls; }

  async ngOnInit() {
    this.memberService.getMember().then(member => {
      this.member = member;
      // Set the customer answers
      this.answers = this.member.q;
      // Once the member has loaded then set the email and disable the control
      this.form.email.setValue(this.member.email);
      this.form.email.disable();
      // Set member attributes
      this.setMemberDetails();

      this.loadQuestions();
    });
  }

  // Requests the customer questions from the department
  loadQuestions() {
    this.departmentService.getParentDepartment().then(parentCompany => {
      this.departmentService.set(parentCompany);

      // Get the customer questions
      this.departmentService.getCustomerQuestions().then(questions => {
        this.customerQuestions = questions;

        for (let index = 0; index < this.customerQuestions.length; index++) {
          const question = this.customerQuestions[index];
          question.visible = false;
          question.reference = question.id;
          if (!question.admin_only) {
            if (!question.settings.hasOwnProperty('conditional_question')) {
              question.visible = true;
            }

            const questionControl = question.id;
            if (question.default == 1 && question.detail_type == 'check' || question.default == 1 && question.detail_type == 'check-price') {
              if (question.required) {
                this.memberDetailsForm.addControl(questionControl, new FormControl(true, Validators.required));
              } else {
                this.memberDetailsForm.addControl(questionControl, new FormControl(true));
              }
            } else {
              if (question.required) {
                this.memberDetailsForm.addControl(questionControl, new FormControl('', Validators.required));
              } else {
                this.memberDetailsForm.addControl(questionControl, new FormControl(''));
              }
            }
          }
        }
        this.populateQuestions();
      }, err => {
        this.alertService.error(this.translateService.instant('COMMON.GENERAL_ERR'));
        this.spinner.hide();
      });
    });
  }

  // Once the form has loaded and the questions have been rerieved then populate
  // the form with the users customer questions
  populateQuestions() {
    for (const key in this.answers) {
      const ans = this.answers[key];
      this.form[key].setValue(ans.answer);
    }
    this.onQuestionChange();
  }

  // Handles conditional questions and updates the view accordingly
  onQuestionChange() {
    const customerQuestions = this.customerQuestions
    // Conditional questions logic
    for (let index = 0; index < customerQuestions.length; index++) {
      const q = customerQuestions[index];
      // If the question has settings and relies on a conditional question
      if (q.settings && q.settings.hasOwnProperty('conditional_question')) {
        // Get the conditional question based on the id set in q
        const conditionalQ = customerQuestions.filter(question => question.id == q.settings.conditional_question);
        // Get the question control
        const questionControl = this.form[q.settings.conditional_question];
        // If the question control has a value get the option from the contional question. This will allow
        // us to check if q is shown when value is selected
        const option = conditionalQ[0].options.filter(option => option.name == questionControl.value);

        // If the conditional question is visible and the value chosen === to qs conditional answer then show q
        if (conditionalQ.length > 0 && conditionalQ[0].visible && option.length > 0 && q.settings.conditional_answers.hasOwnProperty(option[0].id)) {
          this.form[q.id].enable();
          // If the condiditonal question is important, Hide it
          if (q.important) {
            q.visible = false;
          } else {
            q.visible = true;
          }
        } else {
          q.visible = false;
          this.form[q.id].setValue('');
          this.form[q.id].disable();
        }
      }
    }
  }

  // Cancel any changes made to the form data
  cancel() {
    this.setMemberDetails();
    this.populateQuestions();
  }

  // Set the values in the form using the members details from member object
  setMemberDetails() {
    // Set member attributes
    const fullMobile = `0${this.member.mobile}`
    this.form.first_name.setValue(this.member.first_name);
    this.form.last_name.setValue(this.member.last_name);
    this.form.mobile.setValue(fullMobile);
    this.form.address1.setValue(this.member.address1);
    this.form.address2.setValue(this.member.address2);
    this.form.address4.setValue(this.member.address4);
    this.form.country.setValue(this.member.country);
    this.form.postcode.setValue(this.member.postcode);
  }

  // Submit the form update
  onSubmit() {
    this.alertService.clear();
    this.submitted = true;
    // Create the questions object for the member update request
    this.memberDetailsForm.value['q'] = {};

    for (let index = 0; index < this.customerQuestions.length; index++) {
      const question = this.customerQuestions[index];
      if (question.id === this.bookingQuestionsConfig.schoolNameSelectionAccounts) {
        const test = question.options.filter(option => option.name == this.memberDetailsForm.value[this.bookingQuestionsConfig.schoolNameSelectionAccounts]);
        test.length > 0 ? this.memberDetailsForm.controls[this.bookingQuestionsConfig.schoolNameSelectionAccounts].setErrors(null) : this.memberDetailsForm.controls[this.bookingQuestionsConfig.schoolNameSelectionAccounts].setErrors({ 'selectedSchoolErr': "Please select a school from the list" });
      }
    }

    if (this.memberDetailsForm.invalid) {
      this.alertService.warn(this.translateService.instant('FORM.VALIDATION_ERR_MSG'));
      // scroll to the top of the page if form is invalid
      this.scrollToTop();
      return;
    } else {
      this.spinner.show();

      // Create the q {} containing the updated answers
      for (const key in this.customerQuestions) {
        const question = this.customerQuestions[key];
        const questionObj = {
          answer: this.memberDetailsForm.value[question.id],
          name: question.name
        };
        this.memberDetailsForm.value['q'][question.id] = questionObj;
      }

      // Update the member call
      this.memberService.updateMember(this.member, this.memberDetailsForm.value).then(member => {
        this.memberService.setMember(member);
        this.member = member;
        // Set the customer answers
        this.answers = this.member.q;
        this.alertService.success(this.translateService.instant('ACCOUNT.UPDATE_SUCCESS'));
        // scroll to the top of the page if form update is successful
        this.scrollToTop();
        this.spinner.hide();
        return;
      }).catch((error: HttpErrorResponse) => {
        this.alertService.error(this.translateService.instant('ACCOUNT.UPDATE_FAILED'))
        // scroll to the top of the page if form update is unsuccessful
        this.scrollToTop();
        this.spinner.hide();
      });
    }
  }

  // scroll to the top of the page
  scrollToTop() {
    window.scroll(0,0);
  }

  // Number input text validation, keycode 8 is backspace
  keyPressNumberField(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
}