import { Injectable } from '@angular/core';
import { BBService, BBModel } from 'bookingbug-core-js';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { AppConfig } from 'src/app/app.config';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class MemberService {
  private core = AppConfig.settings.core;
  private apiServer = AppConfig.settings.apiServer;
  member;

  constructor(
    private http: HttpClient
  ) { }

  async getMember() {
    return this.member;
  }

  async setMember(member) {
    this.member = new BBModel.Client(member);
  }

  async checkMemberExists(selectedDepartment, email) {
    const headers: HttpHeaders = new HttpHeaders({
      'App-Id': this.core.app_id,
      'App-Key': this.core.app_key
    });

    const apiUrl = `${this.apiServer.api_url}/api/${this.apiServer.version}/${selectedDepartment.id}/client/find_by_email/${email}`;
    const response = await this.http.get(apiUrl, { headers }).toPromise();
  }

  async updateMember(member, params) {
    const response = await BBService.member.updateMember(member, params);
    const updatedMember = await new BBModel.Member.Member(response);
    sessionStorage.setItem('login', updatedMember.$toStore());
    return response;
  }

  async updatePassword(member, params) {
    return await BBService.login.updatePassword(member, params);
  }

  async getBookings() {
    return await this.member.$getBookings();
  }

  async getBookingsSelectedDate(date) {
    return await this.member.$getBookings({start_date: date, end_date: date});
  }

  async getPastBookings(member) {
    const today = moment();
    const lastYear = moment().subtract(1, 'year');
    const params = {
      start_date: lastYear.toISOString().split('T')[0],
      end_date: today.toISOString().split('T')[0]
    }
    return await member.$getBookings(params);
  }
}
