import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Globals } from 'src/app/globals';
import { faBars, faThumbsDown } from '@fortawesome/free-solid-svg-icons';
import { faLanguage } from '@fortawesome/free-solid-svg-icons';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import * as moment from 'moment-timezone';
import '../../services/i18n/calendar/moment.cy';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, AfterViewInit {
  show: boolean = false;
  showSubmenu: number = 0;
  showDesktopSubmenu: number = 0;
  navHeight;
  @ViewChild('headerIdentifier')
  myIdentifier: ElementRef;
  fixNavbarTop: boolean;
  activeLinks: any = [];
  desktopLinks: any = [];
  faBars = faBars;
  faLanguage = faLanguage;
  faChevronRight = faChevronRight;
  faExternalLinkAlt = faExternalLinkAlt;
  faEllipsisV = faEllipsisV;
  selectedLanguage: number;
  hiddenMenu: any = [];
  showHiddenMenu: boolean;
  hiddenMenuShowClicked: boolean;


  @HostListener('window:scroll', ['$event']) // for window scroll events
  onScroll(event) {
    // if (window.pageYOffset > this.navHeight) {
    //   this.fixNavbarTop = true;
    // } else {
    //   this.fixNavbarTop = false;
    // }
  }
  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent) {
    this.showDesktopSubmenu = 0
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.redrawHiddenMenu();
  }
  
  constructor(
    globals: Globals,
    private titleService: Title,
    private changeDetector: ChangeDetectorRef,
    public translate: TranslateService,
    private dateAdapter: DateAdapter<Date>
  ) {
    translate.addLangs(['en', 'cy']);
    translate.setDefaultLang('en');
    translate.use('en');
    this.dateAdapter.setLocale('en');
    moment.locale('en');

    const browserLang = translate.getBrowserLang();
    translate.use(browserLang.match(/en|cy/) ? browserLang : 'en');

    translate.onLangChange.subscribe((event) => {
      globals.blLanguage = (event.lang == 'en') ? 0 : 1;
      moment.locale(event.lang);
      this.dateAdapter.setLocale(event.lang);
      this.translate.use(event.lang);
      this.selectedLanguage = globals.blLanguage;
      translate.get('COMMON.PAGE_TITLE').subscribe((res: string) => {
        titleService.setTitle(res);
        this.changeDetector.detectChanges();
        this.changeDetector.markForCheck();
      });
      // recalcuate the hidden menu
      this.redrawHiddenMenu();
    });
    this.hiddenMenuShowClicked = false;
  }
  
  // TO CHANGE LANGUAGE -> (click)='translate.use("ie")' || (click)='translate.use("en")'>

  ngOnInit() {
    this.activeLinks = mobileLinks;
    this.desktopLinks = desktopLinks;
    this.showHiddenMenu = false;
    this.hiddenMenuShowClicked = false;
    this.redrawHiddenMenu();
  }

  ngAfterViewInit() {
    this.navHeight = this.myIdentifier.nativeElement.offsetHeight;
    console.log('height:' + this.navHeight);
  }

  linkClicked(title, type) {
    if (type === 'back') {
      this.activeLinks = mobileLinks
    } else {
      var link = this.activeLinks.filter(x => x.title == title)[0]
      if (type == 'route') {
        window.location.href = link.href;
        console.log('route')
      } else {
        this.activeLinks = []
        this.activeLinks = link.links
        this.activeLinks.unshift({ title: ['back', 'back'], type: 'back' });
      }
    }
  }

  redrawHiddenMenu() {
    let viewportWidth = window.innerWidth;
    if(viewportWidth > 751 && viewportWidth < 976) {
      this.hiddenMenu = [];
      const menuItems = document.querySelectorAll('.desktop-menu li');
      menuItems.forEach((el, idx) => {
        if(!this.isInViewport(el)) {
          // add items to sub-menu
          this.hiddenMenu.push(el);
          // only do this when we add an element to not trigger when we have enough screen real estate to show all elements.
          this.showHiddenMenu = true;
        }
      });
    }
    else {
      this.showHiddenMenu = false;
    }
  }

  toggleHiddenMenu() {
    this.hiddenMenuShowClicked = !this.hiddenMenuShowClicked;
  }

  isInViewport(elem) {
    var bounding = elem.getBoundingClientRect();
    return (
        bounding.top >= 0 &&
        bounding.left >= 0 &&
        bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  };

  documentClick() {
    this.showDesktopSubmenu = 0
  }

}

var mobileLinks = [
  {
    home: true,
    title: ['Home', 'Hafan'],
    href: 'https://bcuhb.nhs.wales/'
  },
  // {
  //   home: false,
  //   title: ['COVID-19', 'COVID-19'],
  //   href: 'https://bcuhb.nhs.wales/covid-19/',
  //   links: [
  //     {
  //       title: ['Hospital Visiting','Ymweld â\'r Ysbyty'],
  //       href: 'https://bcuhb.nhs.wales/covid-19/hospital-visiting1/',
  //       links: [
  //         {
  //           title: ['General Visiting', 'General Visiting'],
  //           href: 'https://bcuhb.nhs.wales/covid-19/hospital-visiting1/general-visiting/',
  //         },
  //         {
  //           title: ['Maternity visiting guidance', 'Maternity visiting guidance'],
  //           href: 'https://bcuhb.nhs.wales/covid-19/hospital-visiting1/maternity-visiting-guidance/',
  //         },
  //       ]
  //     },
  //     {
  //       title: ['Long Covid', 'COVID Hir'],
  //       href: 'https://bcuhb.nhs.wales/covid-19/long-covid/',
  //       links: [
  //         {
  //           title: ['COVID-19 rehabillitaion','COVID-19 rehabillitaion'],
  //           href: 'https://bcuhb.nhs.wales/covid-19/long-covid/covid-19-rehabilitation/',
  //         },
  //         {
  //           title: ['Education Programmes for Patients (EPP)','Education Programmes for Patients (EPP)'],
  //           href: 'https://bcuhb.nhs.wales/covid-19/long-covid/education-programmes-for-patients-epp/',
  //         },
  //         {
  //           title: ['Long COVID-19 research in North Wales','Long COVID-19 research in North Wales'],
  //           href: 'https://bcuhb.nhs.wales/covid-19/long-covid/long-covid-19-research-in-north-wales/',
  //         },
  //         {
  //           title: ['COVID-19 Recovery app', 'COVID-19 Recovery app'],
  //           href: 'https://bcuhb.nhs.wales/covid-19/long-covid/covid-19-recovery-app/',
  //         },
  //       ]
  //     },
  //     {
  //       title: ['Restarting planned care', 'Ailddechrau gofal wedi\'i gynllunio'],
  //       href: 'https://bcuhb.nhs.wales/covid-19/restarting-planned-care/'
  //     },
  //     {
  //       title: ['COVID-19 vaccinations leaflets in other languages', 'Taflenni brechlyn COVID-19 mewn ieithoedd eraill'],
  //       href: 'https://bcuhb.nhs.wales/covid-19/covid-19-vaccinations-leaflets-in-other-languages/'
  //     },
  //     {
  //       title: ['COVID-19 FOIs', 'Deddf Rhyddid Gwybodaeth  COVID-19'],
  //       href: 'https://bcuhb.nhs.wales/about-us/information-request/foi-disclosure-log-2020-21/covid-19-foi-requests/'
  //     },
  //     {
  //       title: ['Wearing a face mask with hearing aids', 'Gwisgo mwgwd wyneb gyda chymorthion clyw'],
  //       href: 'https://bcuhb.nhs.wales/covid-19/wearing-a-face-mask-with-hearing-aids/'
  //     },
  //     {
  //       title: ['Staying safe in our Hospitals and Community Sites', 'Aros yn Ddiogel yn ein Hysbytai a\'n Safleoedd Cymuned'],
  //       href: 'https://bcuhb.nhs.wales/services/health-services1/information-about-our-hospitals-and-community-sites/'
  //     },
  //     {
  //       title: ['Outbreaks in North Wales', 'Achosion Covid-19 yng Ngogledd Cymru'],
  //       href: 'https://bcuhb.nhs.wales/news/updates-and-developments/updates/covid-19-outbreaks-in-north-wales/'
  //     }
  //   ]
  // },
  // {
  //   title: ['Services', 'Gwasanaethau'],
  //   href: 'https://bcuhb.nhs.wales/services/',
  //   links: [
  //     {
  //       title: ['Hospitals', 'Ysbytai'],
  //       href: 'https://bcuhb.nhs.wales/services/hospitals/',
  //       // There are like 100 hospital links to be added here
  //     },
  //   ]
  // },
  // {
  //   title: ['Health Advice', 'Cyngor Iechyd'],
  //   href: 'https://bcuhb.nhs.wales/health-advice/',
  //   links: [
  //     {
  //       title: ['Postnatal contraception', 'Dull atal cenhedlu ôl-enedigol'],
  //       href: 'https://bcuhb.nhs.wales/health-advice/postnatal-contraception/',
  //       links: [
  //         {
  //           title: ['Sterilisation', 'Sterilisation'],
  //           href: 'https://bcuhb.nhs.wales/health-advice/postnatal-contraception/sterilisation/',
  //         },
  //         {
  //           title: ['Progesterone Only Pill','Progesterone Only Pill'],
  //           href: 'https://bcuhb.nhs.wales/health-advice/postnatal-contraception/progesterone-only-pill/',
  //         },
  //         {
  //           title: ['Antenatal Screening Wales', 'Antenatal Screening Wales'],
  //           href: 'https://bcuhb.nhs.wales/health-advice/postnatal-contraception/intrauterine-contraception/',
  //         },
  //         {
  //           title: ['Progesterone Implant', 'Progesterone Implant'],
  //           href: 'https://bcuhb.nhs.wales/health-advice/postnatal-contraception/progesterone-implant/',
  //         },
  //         {
  //           title: ['Progesterone Injection','Progesterone Injection'],
  //           href: 'https://bcuhb.nhs.wales/health-advice/postnatal-contraception/progesterone-injection/',
  //         },
  //         {
  //           title: ['Your options','Your options'],
  //           href: 'https://bcuhb.nhs.wales/health-advice/postnatal-contraception/your-options/',
  //         },
  //         {
  //           title: ['Combined Hormonal Contraception','Combined Hormonal Contraception'],
  //           href: 'https://bcuhb.nhs.wales/health-advice/postnatal-contraception/combined-hormonal-contraception/',
  //         },
  //       ],
  //     },
  //     {
  //       title: ['Help with my weight', 'Cymorth gyda\'m Pwysau'],
  //       href: 'https://bcuhb.nhs.wales/health-advice/help-with-my-weight/',
  //       links: [
  //         {
  //           title: 'Weight Management Referral Form',
  //           href: 'https://bcuhb.nhs.wales/health-advice/help-with-my-weight/weight-management-referral-form/'
  //         },
  //         {
  //           title: 'The self-referral form',
  //           href: 'https://www.smartsurvey.co.uk/s/Weight-Management-Self-Referral-Form/'
  //         },
  //       ]
  //     },
  //     {
  //       title: ['Mental Capacity Act (MCA) when making decisions', 'Galluedd Meddyliol wrth wneud penderfyniadau'],
  //       href: 'https://bcuhb.nhs.wales/health-advice/mental-capacity-act-mca-when-making-decisions/',

  //     },
  //     {
  //       title: ['End of Life Care (EoLC)', 'Gofal Diwedd Oes'],
  //       href: 'https://bcuhb.nhs.wales/health-advice/end-of-life-care-eolc/',

  //     },
  //     {
  //       title: ['Mental Health Hub', 'Hwb Iechyd Meddwl'],
  //       href: 'https://bcuhb.nhs.wales/health-advice/mental-health-hub/',
  //       links: [
  //         {
  //           title: 'I CAN',
  //           href: 'https://bcuhb.nhs.wales/health-advice/mental-health-hub/i-can/'
  //         },
  //         {
  //           title: 'Get involved with Caniad',
  //           href: 'https://bcuhb.nhs.wales/health-advice/mental-health-hub/get-involved-with-caniad/'
  //         },
  //         {
  //           title: 'Support in a crisis',
  //           href: 'https://bcuhb.nhs.wales/health-advice/mental-health-hub/support-in-a-crisis/'
  //         },
  //         {
  //           title: 'How to access support',
  //           href: 'https://bcuhb.nhs.wales/health-advice/mental-health-hub/how-to-access-support/'
  //         },
  //         {
  //           title: 'I CAN',
  //           href: 'https://bcuhb.nhs.wales/health-advice/mental-health-hub/i-can/'
  //         },
  //         {
  //           title: 'Self-help resources',
  //           href: 'https://bcuhb.nhs.wales/health-advice/mental-health-hub/self-help-resources/'
  //         },
  //       ]
  //     },
  //     {
  //       title: ['SilverCloud - Free online mental health therapy', 'SilverCloud - Therapi ar-lein am ddim ar gael heb gyfeiriad gan eich Meddyg Teulu'],
  //       href: 'https://bcuhb.nhs.wales/health-advice/silvercloud-free-online-mental-health-therapy/',
  //       links: [
  //         {
  //           title: 'SilverCloud',
  //           href: 'https://bcuhb.nhs.wales/health-advice/silvercloud-free-online-mental-health-therapy/silvercloud/'
  //         },
  //       ]
  //     }
  //   ]
  // },
  // {
  //   title: ['About us', 'Amdanom Ni'],
  //   href: 'https://bcuhb.nhs.wales/about-us/',
  //   links: [
  //     {
  //       title: ['Board Meetings and Members', 'Cyfarfodydd ac Aelodau\'r Bwrdd'],
  //       href: 'https://bcuhb.nhs.wales/about-us/health-board-meetings-and-members/',
  //     },
  //     {
  //       title: ['Committees and Advisory Groups', 'Pwyllgorau\'r Bwrdd Iechyd a Grwpiau Cynghori'],
  //       href: 'https://bcuhb.nhs.wales/about-us/committees-and-advisory-groups/',
  //     },
  //     {
  //       title: ['Governance and Assurance', 'Trefniadau Llywodraethu a Sicrwydd'],
  //       href: 'https://bcuhb.nhs.wales/about-us/governance-and-assurance/',
  //     },
  //     {
  //       title: ['About the Health Board', 'Gwybodaeth am y Bwrdd Iechyd'],
  //       href: 'https://bcuhb.nhs.wales/about-us/about-the-health-board/',
  //     },
  //     {
  //       title: ['Our Plans', 'Ein Cynlluniau'],
  //       href: 'https://bcuhb.nhs.wales/about-us/our-plans/',
  //     },
  //     {
  //       title: ['Information Request', 'Ceisiadau Rhyddid Gwybodaeth'],
  //       href: 'https://bcuhb.nhs.wales/about-us/information-request/',
  //     }
  //   ]
  // },
  // {
  //   title: ['News', 'Newyddion'],
  //   href: 'https://bcuhb.nhs.wales/news/',
  //   links: [
  //     {
  //       title: ['Health Board News', 'Newyddion o\'r Bwrdd Iechyd'],
  //       href: 'https://bcuhb.nhs.wales/news/health-board-news/',
  //     },
  //     {
  //       title: ['Updates and Developments', 'Diweddariadau a Datblygiadau'],
  //       href: 'https://bcuhb.nhs.wales/news/updates-and-developments/',
  //     },
  //     {
  //       title: ['Awyr Las: Your Local NHS Charity', 'Awyr Las: Eich Elusen GIG Lleol'],
  //       href: 'https://bcuhb.nhs.wales/news/awyr-las-your-local-nhs-charity/',
  //     },
  //   ]
  // },
  // {
  //   title: ['Train Work Live', 'Hyfforddi Gweithio Byw'],
  //   href: 'https://bcuhb.nhs.wales/train-work-live/',
  //   links: [
  //     {
  //       title: ['Train', 'Hyfforddi'],
  //       href: 'https://bcuhb.nhs.wales/train-work-live/train/',
  //     },
  //     {
  //       title: ['Work', 'Gweithio'],
  //       href: 'https://bcuhb.nhs.wales/train-work-live/work/',
  //     },
  //     {
  //       title: ['Volunteer', 'Gwirfoddoli'],
  //       href: 'https://bcuhb.nhs.wales/train-work-live/volunteer/',
  //     },
  //     {
  //       title: ['Live', 'Byw'],
  //       href: 'https://bcuhb.nhs.wales/train-work-live/live/',
  //     },
  //   ]
  // },
  // {
  //   title: ['Contact us', 'Cysylltwch â Ni'],
  //   href: 'https://bcuhb.nhs.wales/contact-us/'
  // },
  {
    title: ['NHS Wales', 'NHS Wales'],
    href: 'http://www.wales.nhs.uk/'
  },
]

var desktopLinks = [
  {
    title: ['Home', 'Hafan'],
    href: 'https://bcuhb.nhs.wales',
    submenuId: 0
  },
  // {
  //   title: ['COVID-19', 'COVID-19'],
  //   href: 'https://bcuhb.nhs.wales/covid-19/',
  //   submenuId: 1
  // },
  // {
  //   title:['Services', 'Gwasanaethau'],
  //   href: 'https://bcuhb.nhs.wales/health-advice/',
  //   submenuId: 2
  // },
  // {
  //   title:  ['Health Advice', 'Cyngor Iechyd'],
  //   href: 'https://bcuhb.nhs.wales/services/',
  //   submenuId: 3
  // },
  // {
  //   title: ['About us', 'Amdanom Ni'],
  //   href: 'https://bcuhb.nhs.wales/about-us/',
  //   submenuId: 4
  // },
  // {
  //   title: ['News', 'Newyddion'],
  //   href: 'https://bcuhb.nhs.wales/news/',
  //   submenuId: 5
  // },
  // {
  //   title: ['Train Work Live', 'Hyfforddi Gweithio Byw'],
  //   href: 'https://bcuhb.nhs.wales/train-work-live/',
  //   submenuId: 6
  // },
  // {
  //   title: ['Contact us', 'Cysylltwch â Ni'],
  //   href: 'https://bcuhb.nhs.wales/contact-us/',
  //   submenuId: 0
  // }
]