import { Injectable } from '@angular/core';
import { BBModel, BBService, context } from 'bookingbug-core-js';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(
    private router: Router,
  ) { }

  async loginMember(company, loginRequest) {
    return await this.companyLogin(company, {}, loginRequest);
  }

  async resetPassword(company, resetForm) {
    return await BBService.login.sendPasswordReset(company, resetForm);
  }

  companyLogin(company, params, form) {
    return new Promise((resolve, reject) => {
      company.$post('login', params, form).then((login) => {
        sessionStorage.setItem('auth_token', login.auth_token);
        localStorage.setItem('auth_token', login.auth_token);

        return login.$get('member').then((member) => {
          this.setLogin(member, null);
          return resolve(member);
        }
          , (err) => {
            return reject(err);
          }
        );
      }
        , (err) => {
          return reject(err);
        }
      );
    });
  }

  setLogin(member, persist) {
    const auth_token = member.getOption('auth_token');
    member = new BBModel.Member.Member(member);
    sessionStorage.setItem('login', member.$toStore());
    context.member = member;
    if (persist) {
      // save both auth_token and login to re-use them
      sessionStorage.setItem('login', member.$toStore());
    }
    return member;
    
  }

  isLoggedIn(): boolean {
    if (localStorage.getItem('auth_token')) {
      return true;
    } else {
      return false;
    }
    
  }

  logout() {
    sessionStorage.removeItem("auth_token");
    sessionStorage.removeItem("login");
    // remove the value from localStorage
    localStorage.removeItem("auth_token");
    localStorage.removeItem("auth_token");
    this.router.navigate(['/departments']);
  }
}

