import { Injectable, EventEmitter } from '@angular/core';
import { DatastoreService } from './datastore.service';
import { AvailabilityService } from './jrni/availability.service';
import * as moment from 'moment';
import { DepartmentService } from './jrni/department.service';
import { AppConfig } from "src/app/app.config";


@Injectable({
  providedIn: 'root'
})
export class CalendarService {
  currentlySelectedSlot;
  unselectedLocations = [];
  savedLocations = [];
  private core = AppConfig.settings.core;

  onLocationChange = new EventEmitter();

  constructor(
    private availabilityService: AvailabilityService,
    private datastoreService: DatastoreService,
    private departmentService: DepartmentService
  ) { }

  getUnselectedLocations() {
    return this.datastoreService.unselectedLocations;
  }

  async checkAvailability(service, isoTime, reset = false) {
    // Allow us to reset the saved locations. This fixes an issue where you are unable to reselect a previous location.
    if (reset) {
      this.savedLocations = [];
      this.unselectedLocations = [];
    }
    if (this.savedLocations != null && this.savedLocations.length >= 1) {
      // If we've already done the check for slots, just return what we currently have
      let locations = this.savedLocations;

      for (let i = 0; locations.length > i; i++) {
        for (let x = 0; locations[i]?.slots.length > x; x++) {
          let slot = locations[i]?.slots[x].start;
          // Check through all slots, if there are any available before the currently selected slot, display it
          if (Date.parse(slot) < Date.parse(this.currentlySelectedSlot)) {
            locations[i].hasEarlierAvailability = true
          }
        }
      }

      return locations;

    } else {
      this.savedLocations = [];
      // If we haven't already done the check for slots, do the check and return those
      var allLocations = this.datastoreService.unselectedLocations;

      for (let i = 0; allLocations.length > i; i++) {

        const params = {
          'service_id': service.id,
          'start_date': isoTime,
          'end_date': isoTime,
        };
        // dont add slots from the alt company
        if (allLocations[i].membership_id.toString() !== this.core.alt_company_id) {
          // Only add slots with availability
          await this.availabilityService.getSlots(allLocations[i], params).then(res => {
            allLocations[i].slots = [];
            if (res) {
              for (let time of res['times']) {
                if (time.available) {
                  allLocations[i].slots.push(time);
                }
              }
              allLocations[i].slots.sort((a, b) => a.time - b.time);
            }

          });
        }
      }

      let locations = [];

      // If location has slots, all to allLocations
      for (let x = 0; allLocations.length > x; x++) {
        if (allLocations[x].slots?.length >= 1) {
          locations.push(allLocations[x]);
          // Save allLocations to be used later
          this.savedLocations.push(allLocations[x]);
        }
      }

      // Check through & see if there is availability before the currently selected slot
      for (let i = 0; locations.length > i; i++) {
        for (let x = 0; locations[i]?.slots?.length > x; x++) {
          let slot = locations[i]?.slots[x].start;
          if (Date.parse(slot) < Date.parse(this.currentlySelectedSlot)) {
            locations[i].hasEarlierAvailability = true;
          }
        }
      }
      return locations;
    }
  }

  switchLocation(location) {
    this.availabilityService.setSelectedSlot(null);
    this.onLocationChange.emit(location);
  }

  getNewLocationSlots(locationId): any {
    if(this.savedLocations) {
      var location = this.savedLocations.filter(x => x.id == locationId);
      return location;
    }
    return null;
  }

}