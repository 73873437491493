import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { DepartmentService } from "src/app/services/jrni/department.service";
import { ServicesService } from "src/app/services/jrni/services.service";
import { AvailabilityService } from "src/app/services/jrni/availability.service";
import { BasketService } from "src/app/services/jrni/basket.service";
import { MemberService } from "src/app/services/jrni/member.service";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { NgxSpinnerService } from "ngx-spinner";
import { DatastoreService } from "src/app/services/datastore.service";
import { AppConfig } from "src/app/app.config";
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { AlertService } from "src/app/_alert";
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from "src/app/services/jrni/api.service";
import * as moment from 'moment';
import { QuestionsService } from "src/app/services/questions.service";

@Component({
  selector: "app-summary",
  templateUrl: "./summary.component.html",
  styleUrls: ["./summary.component.scss"],
})
export class SummaryComponent implements OnInit {
  selectedDepartment;
  selectedLocation;
  selectedService;
  pageTitle: string;
  summaryPageText: string;
  selectedSlot;
  selectedResource;
  endSlot;
  termsAndConditions;
  submitted = false;
  items;
  member;
  client;
  address;
  lat;
  long;
  bookingQuestions = [];
  bookingAnswers = [];
  displayTnCs: boolean;
  bookingQuestionsConfig = AppConfig.settings.bookingQuestions;
  bookingSummaryData = [];
  companySummaryData = [];
  total: number = 0;
  extraDetailsPresent = false;
  slotAvailable = true;
  dateOfBirth = '';

  constructor(
    private router: Router,
    private departmentService: DepartmentService,
    private servicesService: ServicesService,
    private availabilityService: AvailabilityService,
    private basketService: BasketService,
    private memberService: MemberService,
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private datastoreService: DatastoreService,
    private alertService: AlertService,
    public translateService: TranslateService,
    private apiService: ApiService,
    private questionsService: QuestionsService,
  ) { }

  routerGoBack() {
    this.router.navigate(["/booking-details"]);
  }

  restartJourney() {
    this.router.navigate(["/departments"]);
  }

  ngOnInit() {
    this.scrollToTop();
    this.alertService.clear();
    this.servicesService.get().then(async (company) => {
      if (company === undefined) {
        // company not found, restart
        this.restartJourney();
      } else {
        // Set the questions and answer
        this.bookingQuestions = this.datastoreService.bookingQuestions;
        this.client = this.datastoreService.customerDetailsForm.value;

        // Get the selected location
        // Use this.departmentService.getLocation() if the services are set on the children departments
        // Use this.departmentService.get() if the services are set on the parent department
        this.departmentService.getLocation().then((location) => {
          this.selectedLocation = location;

          this.address = location.address;
          this.datastoreService.address = location.address;
        });
        // Get the selected slot
        if (this.datastoreService.selectedSlot) {
          this.availabilityService.getSelectedSlot().then((slot) => {
            this.selectedSlot = slot;
            this.availabilityService.getEndSlot().then((slot) => {
              this.endSlot = slot;
            });
            this.availabilityService.getSelectedResource().then((resource) => {
              if (resource) {
                this.selectedResource = resource.id;
              }
            });
          });
        }
        // don't know exactly what the id of the date question is
        if (this.datastoreService.customerDetailsForm.controls["1"] !== undefined) this.dateOfBirth = this.getDOB(this.datastoreService.customerDetailsForm.controls['1'].value);
        if (this.datastoreService.customerDetailsForm.controls["279"] !== undefined) this.dateOfBirth = this.getDOB(this.datastoreService.customerDetailsForm.controls['279'].value);
        if (this.datastoreService.customerDetailsForm.controls["34"] !== undefined) this.dateOfBirth = this.getDOB(this.datastoreService.customerDetailsForm.controls['34'].value);

        // Use this.departmentService.getLocation() if the services are set on the children departments
        // Use this.departmentService.get() if the services are set on the parent department
        this.departmentService.get().then((selectedDepartment) => {
          if (selectedDepartment === undefined) {
            this.restartJourney();
          } else {
            this.selectedDepartment = selectedDepartment;

            // Get the configurable data
            if (this.selectedDepartment.hasOwnProperty("extra")) {
              // Set the title on the login page to the title from the business question
              this.selectedDepartment.extra.hasOwnProperty("review_page_title")
                ? (this.pageTitle = this.selectedDepartment.extra.review_page_title)
                : (this.pageTitle = "Review title not set");
              // Set the extra text on the page to the text taken from the business question
              this.selectedDepartment.extra.hasOwnProperty("summary_page_text")
                ? (this.summaryPageText = this.selectedDepartment.extra.summary_page_text)
                : null;
              // Tncs
              this.getTermsAndConditions();
            }

            // Use this.departmentService.getLocation() if the services are set on the children departments
            // Use this.departmentService.get() if the services are set on the parent department
            this.departmentService.getLocation().then((location) => {
              this.selectedLocation = location;
            });

            // Get the selected service
            this.servicesService.get().then((service) => {
              this.selectedService = service

              this.datastoreService.bookingQuestions.forEach(question => {
                if (this.datastoreService.bookingForm.value[question['id']]) {
                  this.bookingSummaryData.push({
                    name: question.name,
                    answer: this.datastoreService.bookingForm.value[question.id],
                    detail_type: question.detail_type,
                    price: question.price
                  });
                }
              });
              this.datastoreService.companyQuestions.forEach(question => {
                if (this.datastoreService.companyQuestionsForm.value[question['id']]) {
                  this.companySummaryData.push({
                    name: question.name,
                    answer: this.datastoreService.companyQuestionsForm.value[question.id],
                    detail_type: question.detail_type,
                    price: question.price
                  });
                }
              });

              // Check if the questions contain any other purchase details
              const extraDetails = this.bookingSummaryData.filter(elem => elem.detail_type !== 'check-price');
              extraDetails.length > 0 ? this.extraDetailsPresent = true : this.extraDetailsPresent = false;
              // Calculate purchase total
              const paidItems = this.bookingSummaryData.filter(item => item.detail_type === 'check-price');
              this.total = paidItems
                .map((items) => items.price)
                .reduce((a, b) => a + b, 0) + this.selectedService?.price;
            });

            // Get the selected slot
            this.availabilityService.getSelectedSlot().then((slot) => {
              this.selectedSlot = slot;
            });
          }
        });
      }
    });
  }

  getDOB(dateString) {
    var birthDate = new Date(dateString);
    return moment(birthDate).format('DD/MM/YYYY');
  }

  getTermsAndConditions() {
    if (this.selectedDepartment.hasOwnProperty("extra")) {
      this.displayTnCs = true;
      // Check if the terms and conditions are on/off
      if (
        this.selectedDepartment.extra.hasOwnProperty("display_tncs") &&
        this.selectedDepartment.extra.display_tncs.toLowerCase() === "yes"
      ) {
        this.termsAndConditions = "";
        // Loop through all the extra pre defined fields
        const extraQuestions = this.selectedDepartment.extra;
        for (const key in extraQuestions) {
          if (extraQuestions.hasOwnProperty(key)) {
            const element = extraQuestions[key];
            if (key.indexOf("terms_and_conditions") >= 0) {
              if (!this.termsAndConditions) {
                this.termsAndConditions += element;
              } else {
                this.termsAndConditions += " " + element;
              }
            }
          }
        }
      } else {
        this.displayTnCs = false;
      }
    }
  }

  // create client
  async createClient() {
    let customerQs = [];

    // For each question in the form check if the customer question exists and if so add to customerAnswers []
    for (const control in this.datastoreService.customerDetailsForm.value) {
      if (this.datastoreService.customerDetailsForm.value.hasOwnProperty(control)) {
        const element = this.datastoreService.customerDetailsForm.value[control];
        const question = this.datastoreService.customerQuestions.filter(question => question.id.toString() === control);
        let q = {};
        if (question.length > 0 && element !== "") {
          q = {
            id: question[0].id,
            answer: element
          }

          customerQs.push(q);
        }
      }
    }

    const customerDetailsValue = this.datastoreService.customerDetailsForm.value;

    const params = {
      first_name: customerDetailsValue.first_name,
      last_name: customerDetailsValue.last_name,
      email: customerDetailsValue.email_address,
      mobile: customerDetailsValue.mobile_number,
      postcode: customerDetailsValue.postcode
        .trim()
        .replace(" ", ""),
      client_type: "contact",
      questions: customerQs
    };

    try {
      const clientRes = await this.selectedDepartment.$post('client', {}, params);
      if (clientRes) {
        this.datastoreService.client = clientRes;
        console.log('datastore client', this.datastoreService.client);
        return true;
      }
      else {
        return false;
      }
    }
    catch (err) {
      console.error(err);
      this.alertService.error(this.translateService.instant('COMMON.LOAD_ISSUE'));
      this.spinner.hide();
      return false;
    }
  }

  async addItem() {
    // Get the selected slot from the previous page
    const selectedSlot = await this.availabilityService.getSelectedSlot();


    // Get the booking answers and add them to an array ready to add to basket
    for (var key in this.datastoreService.bookingForm.value) {
      {
        this.bookingAnswers.push({
          id: key,
          answer: this.datastoreService.bookingForm.value[key],
        });
      }
    }

    // const questions = (await this.questionsService.getCompanyQuestions().toPromise())['_embedded']  
    // const altQuestions = (await this.questionsService.getAltCompanyQuestions().toPromise())['_embedded']

    const questions:any = await this.questionsService.getCompanyQuestions().toPromise().then(questions => {return questions})
    // const altQuestions:any = await this.questionsService.getAltCompanyQuestions().toPromise().then(questions => {return questions})

    // add the extra questions from the departments page 
    // we do all this because we get the answers to several questions before we know the service
    //so we need to convert the base service's questions too the alt ones, and the only shared identifier (that we can access) is name
    for (var key in this.datastoreService.companyQuestionsForm.value) {

      const myQuestion = questions.questions.filter(x => x.id.toString() === key)[0]
      // const myALtQuestion = altQuestions.questions.filter(x => x.name === myQuestion.name)[0]

      this.bookingAnswers.push({
        id: key,
        answer: this.datastoreService.companyQuestionsForm.value[key],
      });
      // if(myALtQuestion !== undefined){
      //   this.bookingAnswers.push({
      //     id: myALtQuestion.id,
      //     answer: this.datastoreService.companyQuestionsForm.value[key],
      //   });
      // } else{
      //   alert("administrative error: Denbigh and Ruthin's question titles must match those of the other companies (this includes both translations)")
      //   return
      // }

    }

    // Is the booking in Welsh, Auto populate this question
    const usingWelsh = this.translateService.currentLang == "cy" ? 'Welsh' : 'English';
    this.bookingAnswers.push({ id: 271, answer: usingWelsh })
    // this.bookingAnswers.push({ id: 39, answer: usingWelsh })

    this.datastoreService.bookingAnswers = this.bookingAnswers;

    // Add item to the basket
    const res = await this.basketService.addItem(
      this.selectedLocation,
      this.selectedService.id,
      this.selectedService.duration,
      selectedSlot.start,
      this.bookingAnswers,
      this.selectedResource
    )

    if (res == "failed") {
      // If conflict/409
      this.alertService.error(this.translateService.instant('SUMMARY.INVALID_SLOT'));
      this.spinner.hide();
      this.scrollToTop();
    }
  }

  async startCheckout() {
    this.spinner.show();

    // Check if selected slot is still available
    let slotAvailable: boolean

    await this.apiService.checkSlotAvailability(this.selectedDepartment, this.selectedSlot.start).then((res: any) => {
      slotAvailable = res.available;
    })

    if (slotAvailable === false) {
      // If unavailable, then alert user
      this.alertService.error(this.translateService.instant('SUMMARY.INVALID_SLOT'));
      this.scrollToTop()
      this.slotAvailable = false;
      this.spinner.hide();
    } else {
      // If available then checkout
      this.checkout()
    }
  }

  backToCalendar() {
    this.router.navigate(["/calendar"]);
  }

  async checkout() {
    this.alertService.clear();
    this.submitted = true;
    const noNotifications = false;

    const clientCreated = await this.createClient();
    if (clientCreated) {
      console.log('client created');
      await this.addItem();

      // set member as the client,if the login is not required
      if (
        this.departmentService.selectedDepartment.hasOwnProperty("extra") &&
        this.departmentService.selectedDepartment.extra.hasOwnProperty(
          "login_required"
        )
      ) {
        this.departmentService.selectedDepartment.extra.login_required.toLowerCase() ===
          "yes"
          ? (this.member = await this.memberService.getMember())
          : (this.member = await this.datastoreService.client);
      } else {
        this.member = await this.datastoreService.client;
      }

      await this.basketService
        .checkoutBasket(this.selectedLocation, this.member, noNotifications)
        .then((res) => {
          // Get the purchase
          this.basketService.getPurchase().then((purchase) => {
            purchase.$get("bookings").then(async (bookings) => {
              const formData = new FormData();
            });
          });

          this.router.navigate(["/confirmation"]);
        }).catch((error: HttpErrorResponse) => {
          if (error['response'].status === 409) {
            this.alertService.error(this.translateService.instant('SUMMARY.INVALID_SLOT'));
          }
          else {
            this.alertService.error(this.translateService.instant('COMMON.LOAD_ISSUE'));
          }
          this.spinner.hide();
          this.scrollToTop();
        });
    }
  }

  // Scroll to the top of the page
  scrollToTop() {
    window.scroll(0, 0);
  }
}
