import { Injectable } from '@angular/core';
import { BBModel, BBService } from 'bookingbug-core-js';
import { AppConfig } from 'src/app/app.config';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DatastoreService } from '../datastore.service';

@Injectable({
  providedIn: 'root'
})
export class DepartmentService {
  private core = AppConfig.settings.core;
  private apiServer = AppConfig.settings.apiServer;
  parentDepartment;
  selectedDepartment;
  selectedLocation;
  locationsList;
  accessibleResources = [];

  constructor(
    private http: HttpClient,
    private datastoreService: DatastoreService
  ) { }

  async getParentDepartment() {
    return await BBService.company.query(parseInt(this.core.company_id), { excludeChildCompanies: false });
  }

  async getAltParentDepartment() {
    return await BBService.company.query(parseInt(this.core.alt_company_id), { excludeChildCompanies: false });
  }

  async getChildDepartments(parentDepartment) {
    return await parentDepartment.$getChildren();
  }

  async set(department) {
    this.selectedDepartment = department;
  }

  async get() {
    return this.selectedDepartment;
  }

  // Get the list of locations from the selected department.
  // First check if a call was already made and we already have 
  // the locations
  async getSelectedDepartmentChildren(selectedDepartmentId, service_id = null) {
    let params = null;
    if (service_id) {
      params = {
        service_id
      };
    }

    const headers: HttpHeaders = new HttpHeaders({
      'App-Id': this.core.app_id,
      'App-Key': this.core.app_key
    });

    const apiUrl = `${this.apiServer.api_url}/api/${this.apiServer.version}/company/${selectedDepartmentId}/search?service_id=${service_id}`;
    try {
      const details = await this.http.get(apiUrl, { headers }).toPromise();
      const embeddedData = details['_embedded'];
      const locations = [];
      for (const l of embeddedData.companies) {
        const loc = await BBService.company.query(l.id);
        loc.address = l.address;
        locations.push(loc);
      }
      return locations;
    }
    catch (err) {
      console.error(err);
      return [];
    }
  }

  async setLocationList(allLocations) {

    this.locationsList = [];
    this.accessibleResources = [];

    const wheelChairAccess = this.datastoreService.companyQuestionsForm.controls[277].value.toLowerCase().includes("yes") ? true : false;

    if (wheelChairAccess) {
      // If wheel chair filter, only show locations with wheelchair access
      let wcLocations = allLocations;
      let wcAccessibleLocations = [];

      const headers: HttpHeaders = new HttpHeaders({
        'App-Id': this.core.app_id,
        'App-Key': this.core.app_key
      });
      const locationIdsUsed: any = {};
      for (let i = 0; wcLocations.length > i; i++) {
        // Get the resouces for each location to check if WC access
        const apiUrl = `${this.apiServer.api_url}/api/${this.apiServer.version}/${wcLocations[i].id}/resources`;
        try {
          const data = await this.http.get(apiUrl, { headers }).toPromise();
          const resources = data['_embedded'].resources;
          if (resources.length > 0) {
            for (let r of resources) {
              if (r.extra?.hasOwnProperty('wheelchair_access')) {
                if (r.extra?.wheelchair_access == 'Yes') {
                  // we need to store the accessible resource for filtering against later
                  this.accessibleResources.push(r);
                  if (locationIdsUsed[wcLocations[i].id] === undefined) {
                    wcAccessibleLocations.push(wcLocations[i]);
                    locationIdsUsed[wcLocations[i].id] = wcLocations[i].id
                  }
                }
              }
            }
          }
        }
        catch (err) {
          console.log(err);
        }
      }
      wcAccessibleLocations
      // Store WC Array for later use
      this.locationsList = wcAccessibleLocations;
      return await this.filterByService(wcAccessibleLocations);
    } else {
      // If no wheel chair filter, display all locations
      this.locationsList = allLocations;
      return await this.filterByService(allLocations);
    }
  }

  async filterByService(locations) {
    return locations;
  }

  async getLocationList() {
    return this.locationsList;
  }

  async setLocation(location) {
    this.selectedLocation = location;

    // Set location Lat & Long
    if (location.address) {
      this.datastoreService.latitude = location.address.lat;
      this.datastoreService.longitude = location.address.long;
    }
    else {
      try {
        location.$getAddress().then((res) => {
          this.datastoreService.latitude = res.lat;
          this.datastoreService.longitude = res.long;
        });
      }
      catch (err) {
        // Cannot get lat lng from location object
      }
    }
  }

  async getLocation() {
    return this.selectedLocation;
  }

  getAccessibleLocations() {
    return this.accessibleResources;
  }

  async getCustomerQuestions() {
    const headers: HttpHeaders = new HttpHeaders({
      'App-Id': this.core.app_id,
      'App-Key': this.core.app_key
    });

    const apiUrl = `${this.apiServer.api_url}/api/${this.apiServer.version}/${this.selectedDepartment.id}/client_details`;
    const details = await this.http.get(apiUrl, { headers }).toPromise();
    return details['questions'];
  }
}
